// TODO rewrite into hooks using useHistory hook from react-router
import { TABS } from '../layout/navigation/config';

export const replaceFilterSearch = (history, newFilters) => {
  const geolocation = history.location.search
    .split('&')
    .filter(str => str.includes('geolocation='))[0];
  const queryParams = Object.entries(newFilters)
    .map(([key, value]) => {
      if (key === 'geolocation') {
        // geolocation should not be doubled in url, the app should be able to fetch it from map-handled one
        return '';
      }
      if (Array.isArray(value)) {
        if (value.length > 0) {
          return `${key}=${value.join(',')}`;
        }
        return '';
      }
      if (!value) {
        return '';
      }
      return `${key}=${value}`;
    })
    .filter(item => item);
  let search = '';
  if (geolocation && geolocation.length > 0 && queryParams && queryParams.length > 0) {
    search = `${geolocation}&${queryParams.join('&')}`;
  } else if (geolocation && geolocation.length > 0) {
    search = geolocation;
  } else if (queryParams && queryParams.length > 0) {
    search = `?${queryParams.join('&')}`;
  }

  // remove path to specific structure, if present
  let { pathname } = history.location;
  const pathnameArray = pathname.split('/');
  if (pathnameArray.length > 2) {
    pathname = `/${pathnameArray[1]}`;
  }

  history.push({
    pathname,
    search
  });
};

export const updateTab = (history, newTab, options = {}) => {
  let search = '';
  if (options.keepGeolocation) {
    search += history.location.search.split('&').filter(str => str.includes('geolocation='))[0];
  }
  if (options.keepFilters) {
    const filters = history.location.search
      .split('&')
      .filter(str => !str.includes('geolocation='))[0];
    if (search.length === 0) {
      // geolocation is not there
      search += '?';
    } else {
      search += '&';
    }
    search += filters;
  }
  history.push({
    pathname: `/${newTab}`,
    search
  });
};

export const pushSingleFeatureUrl = (history, openedTab, featureId, isLidar) => {
  const currentTab = [TABS.STRUCTURES, TABS.CIP, TABS.QAQC].includes(openedTab)
    ? openedTab
    : 'structures';
  let singleFeatureString;
  if (isLidar) {
    singleFeatureString = 'lidar';
  } else {
    singleFeatureString = [TABS.CIP, TABS.QAQC].includes(currentTab) ? 'pole' : 'structure';
  }
  history.push(`/${currentTab.toLowerCase()}/${singleFeatureString}/${featureId}`);
};
