import strings from '../translate/strings';

// returns arango key based on arango id
// e.g vertices-queries/4 is id and it returns 4 which is arango key
export const arangoKey = arangoId => arangoId.split('/')[1];

/**
 * deepSortedArray
 * @param {array} arr
 * @param {string} sortByProp
 * @returns {array} deep copy of sorted array.
 */
export const deepSortedArray = (arr, sortByProp) => {
  if (!arr) {
    return;
  }
  let deep = JSON.parse(JSON.stringify(arr));
  if (!sortByProp) {
    deep = deep.sort();
  }
  if (typeof sortByProp === 'string') {
    deep = deep.sort((a, b) =>
      typeof a[sortByProp] === 'number'
        ? a[sortByProp] - b[sortByProp] || a[sortByProp].localeCompare(b[sortByProp])
        : a[sortByProp] !== null &&
          b[sortByProp] !== null &&
          a[sortByProp]?.toLowerCase().localeCompare(b[sortByProp]?.toLowerCase())
    );
  }
  // eslint-disable-next-line consistent-return
  return deep;
};

export const arangoUuid = microEvent =>
  // `${microEvent.Context}-${microEvent.MessageType}-${microEvent.Module}${microEvent.EventID}`;
  `${microEvent?.Id}`;

export const unique = points => [...new Set(points.map(JSON.stringify))].map(JSON.parse);

export const getStatus = poleStatus => {
  switch (poleStatus) {
    case 0:
      return strings.infrastructure.noPrediction;
    case 1:
      return strings.infrastructure.predictedDamaged;
    case 2:
      return strings.infrastructure.predictedHealthy;
    case 4:
      return strings.infrastructure.reviewedDamaged;
    case 5:
      return strings.infrastructure.verifiedHealthy;
    case 11:
      return strings.infrastructure.noPrediction;
    case 6:
      return strings.infrastructure.predictedMatch;
    case 7:
      return strings.infrastructure.verifiedMatch;
    case 8:
      return strings.infrastructure.predictedDiscrepancy;
    case 9:
      return strings.infrastructure.verifiedHigh;
    case 10:
      return strings.infrastructure.verifiedLow;
    case 12:
      return strings.infrastructure.verifiedMedium;
    case 'NOT_PREDICTED':
      return strings.infrastructure.noPrediction;
    case 'DISCREPANCIES_PREDICTED':
      return strings.infrastructure.discrepanciesPredicted;
    case 'DISCREPANCIES_VERIFIED':
      return strings.infrastructure.discrepanciesVerified;
    case 'MATCHES_PREDICTED':
      return strings.infrastructure.matchesPredicted;
    case 'MATCHES_VERIFIED':
      return strings.infrastructure.matchesVerified;
    default:
      return '';
  }
};
