export default {
  1102: 'Abandoned Object',
  1101: 'Intrusion Alert',
  1100: 'Person Identified',
  1103: 'Anomaly Alert',
  1104: 'Color Profile',
  1105: 'Counter Changed',
  1106: 'Dwelling Alert',
  1107: 'Face Alert',
  1108: 'Removed Object',
  1109: 'Scene Changed',
  '110A': 'Tampering Alert',
  '110B': 'Target Match',
  '110C': 'Text Alert',
  '110D': 'Tripwire Crossed',
  '110E': 'ZoneCount Alert',
  '110F': 'ZoneEnter Alert',
  1110: 'ZoneExit Alert',
  1111: 'Over Crowding Alert',
  1112: 'Vehicle Identified',
  2000: 'Infrastructure Alert',

  C100: 'Speeding Alert',
  C101: 'Sensor Alert',
  C000: 'Sensor Data',
  C001: 'Lamp Post Data',
  C102: 'Anomaly Alert',

  1010: 'Face Detected',
  1011: 'Object Detected',
  1012: 'Abandoned Object Detected',
  1021: 'Color Profile',
  1022: 'Anomaly Detected',
  1028: 'Licence Plate Detected',

  1300: 'building',
  1302: 'camera',
  1303: 'video',
  1305: 'area',
  1306: 'server',
  1307: 'camera360',
  1308: 'lampPost',
  1309: 'poleWood',
  10309: 'imageShotHided',
  11309: 'imageShotShowed',
  12309: 'imageShot',
  13309: 'imageAngle',
  14309: 'covered',
  '1309-0': 'noPrediction',
  '1309-1': 'predictedDamage',
  '1309-2': 'predictedHealthy',
  '1309-3': 'images',
  '1309-4': 'verifiedDamage',
  '1309-5': 'verifiedHealthy',
  '1309-6': 'matchesPredicted',
  '1309-7': 'matchesVerified',
  '1309-8': 'discrepanciesPredicted',
  '1309-9': 'verifiedDiar',
  '1309-10': 'verifiedNonDiar',
  '1309-12': 'verifiedNonDiar',
  '1309-NOT_PREDICTED': 'noPrediction',
  '1309-DISCREPANCIES_PREDICTED': 'discrepanciesPredicted',
  '1309-MATCHES_PREDICTED': 'matchesPredicted',
  '1309-DISCREPANCIES_VERIFIED': 'discrepanciesVerified',
  '1309-MATCHES_VERIFIED': 'matchesVerified',
  1311: 'poleGroup',
  1312: 'poleSteel',
  '1312-0': 'noPrediction',
  '1312-1': 'predictedDamage',
  '1312-2': 'predictedHealthy',
  '1312-3': 'images',
  '1312-4': 'verifiedDamage',
  '1312-5': 'verifiedHealthy',
  '1312-6': 'predictedMatch',
  '1312-7': 'verifiedMatch',
  '1312-8': 'predictedDiscrepancy',
  '1312-9': 'verifiedDiar',
  '1312-10': 'verifiedNonDiar',
  '1312-12': 'verifiedNonDiar',

  C103: 'action',
  C104: 'agent',
  C105: 'asset',
  C106: 'boundary',
  C107: 'location',
  C108: 'socialMedia',
  C109: 'report',

  AbandonedObjectAlert: '1102',
  IntrusionAlert: '1101',
  PersonIdentifiedAlert: '1100',
  // AnomalyAlert: "1103",
  ColorProfileAlert: '1104',
  CounterChangedAlert: '1105',
  DwellingAlert: '1106',
  FaceAlert: '1107',
  RemovedObjectAlert: '1108',
  SceneChangedAlert: '1109',
  TamperingAlert: '110A',
  TargetMatchAlert: '110B',
  TextAlert: '110C',
  TripwireCrossedAlert: '110D',
  ZoneCountAlert: '110E',
  ZoneEnterAlert: '110F',
  ZoneExitAlert: '1110',
  OverCrowdingAlert: '1111',
  VehicleIdentifiedAlert: '1112',
  SpeedingAlert: 'C100',
  SensorAlert: 'C101',
  AnomalyAlert: 'C102',
  SensorData: 'C000',
  LampPostData: 'C001',
  FaceDetected: '1010',
  ObjectDetected: '1011',
  AbandonedObjectDetected: '1012',
  ColorProfile: '1021',
  AnomalyDetected: '1022',
  LicencePlateDetected: '1028',
  InfrastructureAlert: '2000',
  building: '1300',
  camera: '1302',
  video: '1303',
  area: '1305',
  server: '1306',
  camera360: '1307',
  lampPost: '1308',
  poleWood: '1309',
  imageShot: '12309',
  imageAngle: '13309',
  covered: '14309',
  noImages: '1309-0, 1312-0',
  damages: '1309-1, 1312-1',
  assets: '1309-2 , 1312-2',
  images: '1309-3, 1312-3',
  reviewed: '1309-4, 1312-4',
  poleGroup: '1311',
  poleSteel: '1312',
  action: 'C103',
  agent: 'C104',
  asset: 'C105',
  boundary: 'C106',
  location: 'C107',
  socialMedia: 'C108',
  report: 'C109'
};
