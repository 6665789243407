import {
  SET_INFRASTRUCTURE_ITEMS,
  SET_IDS_TOGGLE,
  SET_SIDEBAR_COLLAPSED,
  UPDATE_SELECTED_DAMAGE,
  SET_NEW_ITEM_FORM_VALUES,
  RESET_NEW_ITEM_FORM,
  SET_SECONDARY_CREATE_FLAG,
  SET_CHILD_ITEM_FLAG,
  SET_ROADS,
  SET_INFRASTRUCTURE_FILTERS,
  ADD_NEW_DAMAGE,
  SET_BOUNDING_BOX_EDIT_OFF,
  SET_BOUNDING_BOX_EDIT_ON,
  SET_INFRASTRUCTURE_ITEM,
  SET_BUCKET,
  SET_DISTRICTS,
  SET_LIDAR_POLYGONS,
  SET_LIDAR_DISTRICTS_HIDDEN,
  SET_FIRE_DISTRICTS,
  HIDE_BIG_IMAGE,
  SHOW_BIG_IMAGE,
  UNSET_POLE_DETAIL,
  HIDE_SMALL_IMAGE,
  SHOW_SMALL_IMAGE,
  SET_SMALL_IMAGE_INDEX,
  SET_FILTER_VISIBILITY,
  SET_BOUNDING_BOX_VISIBILITY,
  SET_IMAGEURL_MAP,
  LOAD_ASSETS,
  LOAD_PROVIDERS,
  SET_ACTIVE_DAMAGE,
  SET_CURRENT_IMAGE,
  RESET_INFRASTRUCTURE_FILTERS,
  RESET_ACTIVE_DAMAGE,
  SET_QAQC_EXPORT_FILE,
  NEGATIVE_FEEDBACK_PANEL,
  SET_PROVIDERS_BY_LEVEL,
  SET_IS_AUTOMATIC_SWITCH_FLAG,
  SET_QAQC_EXPORT_FINISHED,
  SET_PHOTO_EVIDENCE_FOR_DELETION,
  RESET_PHOTO_EVIDENCE_FOR_DELETION,
  SET_CIP_DELETED_ANNOTATION,
  SET_SIDEBAR_SPECIAL_CSS,
  SET_SMALL_MAP_COLLAPSE,
  SET_ADD_NEW_PROVIDER,
  SET_SELECTED_IMAGE,
  SET_DOWNLOAD_MODAL_OPEN,
  SET_DOWNLOAD_PROGRESS_OPEN,
  SET_IMAGES_FOR_DOWNLOAD,
  SET_IMAGE_COUNT_DOWNLOADING,
  SET_IMAGE_DOWNLOADING_DONE,
  TOGGLE_IMAGE_TOOLS_PANEL,
  TOGGLE_LABELING_TOOLS_PANEL,
  SET_OPERATIONAL_DISTRICTS,
  SET_SATELLITE_DISTRICTS,
  SET_COVERAGE_DISTRICTS,
  SET_POLE_COVERAGE,
  UPDATE_IMAGE_BRIGHTNESS,
  UPDATE_IMAGE_CONTRAST,
  RESET_IMAGE_FILTERS,
  SET_HOVERED_LIDAR,
  SET_LIDAR_FILES_FOR_SELECTED_POLE,
  SET_SELECTED_ASSET_GROUPING_INDEX,
  SET_CHECKED_BB,
  SET_CHECKED_ALL,
  SET_WARNING_MESSAGE,
  SET_POLE_DETAIL,
  LOAD_USER_UPLOAD_DROPDOWNS
} from '../../redux/constants/actionTypes';
import normalizeArray from '../../utils/reducers';
import { unique } from '../../utils/vasp-utils';
import { DEFAULT_FILTER_VALUES } from './constants';

export const initialFilters = {
  district: '',
  circuitTieLineId: '',
  facilityId: '',
  detection: '',
  assets: [],
  damages: [],
  material: '',
  networkType: [],
  infrastructure: [],
  status: [],
  holdStatus: null,
  searchWithGeoCoords: true,
  // Cip special fields
  provider: [],
  wireLevel: '',
  tier: '',
  imageSource: '',
  dateFrom: null,
  dateTo: null,
  // lidar filter fields
  lidarDateFrom: null,
  lidarDateTo: null,
  poleStatus: [],
  modelStatus: [],
  vendor: [],
  configuration: [],
  materialModel: [],
  assetType: [],
  lidarFile: ''
};

export const initialState = {
  byId: {},
  displayedItems: [],
  tab: null,
  hoveredLidar: {},
  newItem: false,
  newItemForm: {},
  secondaryNewItem: false,
  instructions: {
    assets: [],
    instructions: []
  },
  filters: initialFilters,
  boundarySelected: '',
  childItem: false,
  importedItems: [],
  selectedBuildingPart: '',
  roads: [],
  isBoundingBoxEditTurnedOn: false,
  poleDetail: {},
  bucket: '',
  districtsById: {},
  lidarPolygons: {},
  lidarFilesForSelectedPole: [],
  fireDistricts: false,
  lidarDistrictsHidden: true,
  operationalDistricts: true,
  satelliteDistricts: false,
  coverageDistricts: false,
  isBigImageVisible: false,
  smallImage: {
    visible: false,
    index: 0
  },
  isFilterVisible: false,
  lastGeoLocation: null,
  isBoundingBoxVisible: true,
  imageUrlMap: {},
  assets: [],
  assetsMarkedForDeletion: [],
  activeDamage: {},
  currentImage: null,
  providers: [],
  qaqcExportFile: null,
  negativeFeedback: false,
  imageToolsPanel: false,
  labelingToolsPanel: true,
  imageBrightness: DEFAULT_FILTER_VALUES.BRIGHTNESS,
  imageContrast: DEFAULT_FILTER_VALUES.CONTRAST,
  assetTree: {},
  assetsById: {},
  reviewAssets: [],
  providersByLevel: [],
  isAutomaticPoleSwitch: false,
  cipDeletedAnnotation: null,
  cipAssets: {},
  visibleLidarPolygons: [],
  sidebarSpecialCss: false,
  smallMapCollapsed: true,
  addNewProvider: false,
  selectedImage: {},
  isDownloadModalOpen: false,
  isDownloadProgressOpen: false,
  imagesForDownload: [],
  imageCountDownloading: {},
  isImageDownloadingDone: false,
  poleCoverage: [],
  streamToggle: false,
  idsToggle: false,
  selectedAssetGroupingIndex: null,
  checkedBB: [],
  checkedAll: true,
  warningMessage: '',
  userUploadDropdowns: {}
};

const infrastructure = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INFRASTRUCTURE_ITEMS:
      return payload !== null
        ? {
            ...state,
            byId: { ...state.byId, ...normalizeArray(payload, 'arangoUuid') },
            displayedItems: unique(payload.map(i => i.arangoUuid))
          }
        : {
            ...state,
            byId: [],
            displayedItems: []
          };
    case SET_IDS_TOGGLE:
      return {
        ...state,
        idsToggle: payload
      };
    case SET_DISTRICTS:
      return {
        ...state,
        districtsById: normalizeArray(payload, 'arangoUuid')
      };
    case SET_LIDAR_POLYGONS:
      return {
        ...state,
        lidarPolygons: normalizeArray(payload, 'arangoUuid')
      };
    case SET_LIDAR_FILES_FOR_SELECTED_POLE:
      return { ...state, lidarFilesForSelectedPole: payload };
    case SET_SIDEBAR_COLLAPSED:
      return { ...state, collapsed: payload };
    case SET_HOVERED_LIDAR:
      return { ...state, hoveredLidar: payload };
    case SET_NEW_ITEM_FORM_VALUES:
      return { ...state, newItemForm: { ...state.newItemForm, ...payload } };
    case RESET_NEW_ITEM_FORM:
      return {
        ...state,
        newItemForm: { ...initialState.newItemForm, ...payload }
      };
    case SET_SECONDARY_CREATE_FLAG:
      return { ...state, secondaryNewItem: payload };
    case SET_CHILD_ITEM_FLAG:
      return { ...state, childItem: payload };
    case SET_ROADS:
      return { ...state, roads: payload };
    case SET_INFRASTRUCTURE_FILTERS:
      return { ...state, filters: { ...state.filters, ...payload } };
    case UPDATE_SELECTED_DAMAGE:
      return { ...state, byId: { ...state.byId, [payload.id]: payload } };
    case ADD_NEW_DAMAGE: {
      return {
        ...state,
        Damages: [...payload.damage]
      };
    }
    case SET_BOUNDING_BOX_EDIT_ON:
      return { ...state, isBoundingBoxEditTurnedOn: true };
    case SET_BOUNDING_BOX_EDIT_OFF:
      return { ...state, isBoundingBoxEditTurnedOn: false };
    case SET_INFRASTRUCTURE_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.Id]: { ...state.byId[payload.Id], ...payload, id: payload.Id }
        },
        poleDetail: { ...state.byId[payload.Id], ...payload },
        displayedItems: unique([...state.displayedItems, payload?.Id?.toString()])
      };
    case SET_POLE_DETAIL:
      return {
        ...state,
        poleDetail: payload
      };
    case UNSET_POLE_DETAIL:
      return {
        ...state,
        tab: payload || null,
        poleDetail: {}
      };

    case SET_FIRE_DISTRICTS:
      return {
        ...state,
        fireDistricts: payload
      };
    case SET_LIDAR_DISTRICTS_HIDDEN:
      return {
        ...state,
        lidarDistrictsHidden: payload
      };
    case SET_BUCKET:
      return {
        ...state,
        bucket: payload
      };
    case SET_OPERATIONAL_DISTRICTS:
      return {
        ...state,
        operationalDistricts: payload
      };
    case SET_SATELLITE_DISTRICTS:
      return {
        ...state,
        satelliteDistricts: payload
      };
    case SET_COVERAGE_DISTRICTS:
      return {
        ...state,
        coverageDistricts: payload
      };
    case SHOW_BIG_IMAGE:
      return {
        ...state,
        isBigImageVisible: true
      };
    case HIDE_BIG_IMAGE:
      return {
        ...state,
        isBigImageVisible: false
      };
    case SHOW_SMALL_IMAGE:
      return {
        ...state,
        smallImage: {
          ...state.smallImage,
          visible: true
        }
      };
    case HIDE_SMALL_IMAGE:
      return {
        ...state,
        smallImage: {
          ...state.smallImage,
          visible: false
        }
      };
    case SET_SMALL_IMAGE_INDEX:
      return {
        ...state,
        smallImage: {
          ...state.smallImage,
          index: payload
        }
      };
    case SET_FILTER_VISIBILITY:
      return {
        ...state,
        isFilterVisible: payload
      };
    case SET_WARNING_MESSAGE:
      return {
        ...state,
        warningMessage: payload
      };
    case SET_BOUNDING_BOX_VISIBILITY:
      return {
        ...state,
        isBoundingBoxVisible: payload
      };
    case SET_IMAGEURL_MAP:
      return {
        ...state,
        imageUrlMap: payload
      };

    case LOAD_ASSETS:
      return {
        ...state,
        assets: payload.assetArray,
        assetsById: normalizeArray(payload.assetArray, 'ClassId'),
        assetTree: payload.assetsTree,
        cipAssets: payload.cipAssets,
        reviewAssets: payload.reviewAssets
      };
    case LOAD_PROVIDERS:
      return {
        ...state,
        providers: payload
      };
    case LOAD_USER_UPLOAD_DROPDOWNS:
      return {
        ...state,
        userUploadDropdowns: payload
      }
    case SET_ACTIVE_DAMAGE:
      return {
        ...state,
        activeDamage: payload
      };
    case RESET_ACTIVE_DAMAGE:
      return {
        ...state,
        activeDamage: initialState.activeDamage
      };
    case SET_CURRENT_IMAGE:
      return {
        ...state,
        currentImage: payload
      };
    case RESET_INFRASTRUCTURE_FILTERS:
      return { ...state, filters: initialState.filters };
    case SET_IS_AUTOMATIC_SWITCH_FLAG:
      return { ...state, isAutomaticPoleSwitch: payload };
    case SET_QAQC_EXPORT_FILE:
      return { ...state, qaqcExportFile: payload };
    case NEGATIVE_FEEDBACK_PANEL:
      return {
        ...state,
        negativeFeedback: payload
      };
    case TOGGLE_IMAGE_TOOLS_PANEL:
      return { ...state, imageToolsPanel: payload };
    case TOGGLE_LABELING_TOOLS_PANEL:
      return { ...state, labelingToolsPanel: payload };
    case SET_PROVIDERS_BY_LEVEL:
      return { ...state, providersByLevel: payload };
    case SET_QAQC_EXPORT_FINISHED:
      return { ...state, qaqcExportFile: initialState.qaqcExportFile };
    case SET_PHOTO_EVIDENCE_FOR_DELETION:
      return { ...state, assetsMarkedForDeletion: [...state.assetsMarkedForDeletion, payload] };
    case RESET_PHOTO_EVIDENCE_FOR_DELETION:
      return { ...state, assetsMarkedForDeletion: initialState.assetsMarkedForDeletion };
    case SET_CIP_DELETED_ANNOTATION:
      return { ...state, cipDeletedAnnotation: payload };
    case SET_SIDEBAR_SPECIAL_CSS:
      return {
        ...state,
        sidebarSpecialCss: payload
      };
    case SET_SMALL_MAP_COLLAPSE:
      return { ...state, smallMapCollapsed: payload };
    case SET_ADD_NEW_PROVIDER:
      return { ...state, addNewProvider: payload };
    case SET_SELECTED_IMAGE:
      return { ...state, selectedImage: payload };
    case SET_DOWNLOAD_MODAL_OPEN:
      return { ...state, isDownloadModalOpen: payload };
    case SET_DOWNLOAD_PROGRESS_OPEN:
      return { ...state, isDownloadProgressOpen: payload };
    case SET_IMAGES_FOR_DOWNLOAD:
      return { ...state, imagesForDownload: payload };
    case SET_IMAGE_COUNT_DOWNLOADING:
      return { ...state, imageCountDownloading: payload };
    case SET_IMAGE_DOWNLOADING_DONE:
      return { ...state, isImageDownloadingDone: payload };
    case SET_POLE_COVERAGE:
      return { ...state, poleCoverage: payload };
    case UPDATE_IMAGE_BRIGHTNESS:
      return { ...state, imageBrightness: payload };
    case UPDATE_IMAGE_CONTRAST:
      return { ...state, imageContrast: payload };
    case RESET_IMAGE_FILTERS:
      return {
        ...state,
        imageBrightness: initialState.imageBrightness,
        imageContrast: initialState.imageContrast
      };
    case SET_SELECTED_ASSET_GROUPING_INDEX:
      return { ...state, selectedAssetGroupingIndex: payload };
    case SET_CHECKED_BB:
      return { ...state, checkedBB: payload };
    case SET_CHECKED_ALL:
      return { ...state, checkedAll: payload };
    default:
      return state;
  }
};

export default infrastructure;
