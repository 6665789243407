import { schema, normalize } from 'normalizr';
/**
 * Normalize Array.
 * Input:
 *    [ { id: '123', name: 'Jim' }, { id: '456', name: 'Jane' } ]
 * Outputs:
 *    {
 *      '123': { id: '123', name: 'Jim' },
 *      '456': { id: '456', name: 'Jane' }
 *    }
 *
 * @param arr Array to normalize
 * @param addIdsIdentifier identifier which should be added as an id
 * @returns {*} Normalized array
 */
const normalizeArray = (arr, addIdsIdentifier) => {
  if (!Array.isArray(arr)) {
    throw new Error('Argument is not an Array');
  }
  if (arr.length === 0) {
    return {};
  }

  let arrCopy = JSON.parse(JSON.stringify(arr));
  // if addIdsIdentifier is defined add to each array item id property based on given identifier
  if (addIdsIdentifier) {
    arrCopy = arr.map(x => ({ ...x, id: x[addIdsIdentifier] }));
  }

  const valueSchema = new schema.Entity('values');
  const valueListSchema = new schema.Array(valueSchema);
  const normalized = normalize(arrCopy, valueListSchema);
  return normalized.entities.values;
};

export default normalizeArray;
