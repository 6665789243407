import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import { Amplify, Logger } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import React from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';
import { theme } from '@innovation-toolkit/ui';
import MaintenancePage from './containers/maintenancePage/MaintenancePage';
import config from './config';
import router from './router';
import LoaderWrap from './components/LoaderWrap';
import ResponsiveCalc from './components/ResponsiveCalc';
import { LOADERS } from './containers/utils/constants';
import { actions as mainActions } from './redux/commonReducers/mainReducer';

import NavBar from './layout/navigation/NavBarContainer.jsx';

localStorage.clear();

Auth.configure({ storage: window.sessionStorage });

Amplify.configure({
  Auth: config.Auth
});

Amplify.register(Auth);
const logger = new Logger('App-Logger');

class App extends React.Component {
  async componentDidMount() {
    axios.interceptors.request.use(
      axiosConfig =>
        Auth.currentSession().then(session => {
          const jwtToken = session.getAccessToken().getJwtToken();
          return { ...axiosConfig, headers: { ...axiosConfig.headers, Authorization: jwtToken } };
        }),
      error => Promise.reject(error)
    );

    if (!this.props.customState) {
      this.props.setCustomState(
        Object.fromEntries(new URLSearchParams(window.location.search).entries()).state
      );
    }

    await Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        if (this.props.customState) {
          const validateUrl = value => /^https?:\/\/\w/.test(value);

          const isHex = str => {
            const regexp = /^[0-9a-fA-F]+$/;
            if (str && regexp.test(str)) {
              return true;
            }
            return false;
          };

          let data = decodeURIComponent(this.props.customState).split('-')[1];
          let buffer;

          // decode redirectURL from hexadecimal to string and make redirect if current URL is different
          if (data && data !== '' && isHex(data)) {
            buffer = Buffer.from(data, 'hex');
            data = buffer.toString('ascii');
            if (data && validateUrl(data) && window.location.href !== data) {
              window.location.href = DOMPurify.sanitize(data);
            }
          }
        }

        if (user) {
          let userRole = user.attributes['custom:groups'];
          if (userRole) {
            if (!Array.isArray(userRole)) {
              if (userRole[0] === '[' && userRole[userRole.length - 1] === ']') {
                userRole = userRole.replace(/[[\]"']/g, '').split(',');
              } else {
                userRole = [userRole];
              }
            }
            userRole = userRole.map(role => role.trim());
          } else {
            userRole = [];
          }
          this.props.setAuthenticatedUser(user.username, userRole);
        } else {
          this.props.setAuthenticated(false);
        }
      })
      .catch(e => {
        if (typeof e === 'string' && e.includes('not authenticated')) {
          Auth.federatedSignIn({
            provider: 'Sempra',
            customState: window.location.href
          });
        } else logger.error(e);
      });
  }

  signOut = async function () {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      /* eslint-disable no-console */
      console.log('error signing out: ', error);
    }
  };

  render() {
    return this.props.isAuthenticated ? (
      <div>
        <ThemeProvider theme={theme}>
          <ResponsiveCalc />
          <LoaderWrap className="dynamic-loader-wrapper" loader={LOADERS.GLOBAL}>
            {process.env.REACT_APP_MAINTENANCE === 'true' ? (
              <MaintenancePage />
            ) : (
              <>
                <NavBar signOut={this.signOut} />
                {router}
              </>
            )}
          </LoaderWrap>
        </ThemeProvider>
      </div>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.main.isAuthenticated,
  customState: state.main.customState
});

const mapDispatchToProps = {
  setAuthenticatedUser: mainActions.setAuthenticatedUser,
  setAuthenticated: mainActions.setAuthenticated,
  setCustomState: mainActions.setCustomState
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
