import LocalizedStrings from 'react-localization';

const lang = {
  en: {
    main: {
      confirmTitle: 'Do you want to close forensic search?',
      confirmContent: 'All data will be lost',
      hide: 'Hide bottom panel',
      show: 'Show bottom panel',
      clear: 'Clear all',
      confirmClearBottomPanel: 'Do you want to clear bottom panel?'
    },
    auth: {
      success: 'Welcome! Logged in Video Analytics UI.',
      failed: 'You have entered an invalid username or password.',
      title: 'Video Analytics UI login',
      usernameComment: 'Please input your username!',
      username: 'Username',
      passwordComment: 'Please input your Password!',
      passwordConfirmComment: 'Please confirm your Password!',
      password: 'Password',
      testCredentials: 'Test credentials',
      description: 'username: admin, password: secret',
      closeText: 'Close Now',
      remember: 'Remember me',
      forgotpassw: 'Forgot password',
      login: 'Log in',
      register: 'register now!',
      logout: 'Logout',
      updateSuccess: 'Update user details was successful!',
      updateFailed: 'Update user details has failed!',
      userDetails: ' User Details',
      firstName: 'First Name',
      lastName: 'Last Name',
      profilePhoto: 'Profile Photo',
      registerTitle: 'Registration',
      registerConfirm: 'Register',
      backToLogin: 'Back to login page',
      registerUserFailed: 'User registration has failed.',
      registerUserSuccess: 'User registration has been successful.',
      currentPassword: 'Current Password',
      newPassword: 'New Password ',
      repeatNewPassword: 'Repeat New Password',
      updatePasswordSuccess: 'Change password was successful!',
      updatePasswordFailed: 'Change password has failed!',
      matchPassword: 'Password did not match!',
      profile: 'Profile',
      changePassword: 'Change Password'
    },

    sideBar: {
      search: 'Search',
      home: 'Home',
      areas: 'Areas',
      poles: 'Poles',
      structures: 'Structures',
      cip: 'CIP',
      potree: 'Potree',
      datasets: 'Datasets',
      qaqc: 'QAQC',
      reports: 'Reports',
      filter: 'Filter',
      logout: 'Sign Out',
      admin: 'Admin',
      wsd: 'Wsd',
      uas: 'User Upload'
    },

    settings: {
      settingsHeader: 'Settings',
      checkAll: 'Check All',
      filters: 'Filters',
      1300: 'Building',
      1302: 'Camera',
      1303: 'Video',
      1305: 'Area',
      1306: 'Server',
      1307: 'Camera 360°',
      1308: 'Lamp post',
      C103: 'Action',
      C104: 'Agent',
      C105: 'Asset',
      C106: 'Boundary',
      C107: 'Location of Interest',
      C108: 'Social Media Report',
      C109: 'ECC Report'
    },

    infrastructure: {
      csvExport: 'CSV EXPORT',
      enterValue: 'Enter keyword or file name',
      enterCircuit: 'Enter Circuit/Tie Line ID',
      dateTime: 'Date / Time',
      reportsReviewDate: 'Review Date',
      damagedAsset: 'Is this asset damaged?',
      damage: 'Damage',
      asset: 'Asset',
      asset_s: 'Asset(s)',
      assetVariation: 'Asset Variation',
      specificDamage: 'Specific Damage',
      imageName: 'Image Name',
      itemName: 'Item',
      itemMessageType: 'Type',
      backToList: 'Back to list',
      returnToList: 'Return to list',
      cancel: 'Cancel',
      clear: 'Clear',
      save: 'Save',
      edit: 'Edit',
      remove: 'Remove',
      comment: 'Comment',
      comments: 'Comments',
      captureComment: 'Capture Comment',
      showComments: 'Show comments',
      hideComments: 'Hide comments',
      showFeedbacks: 'Show feedbacks',
      hideFeedbacks: 'Hide feedbacks',
      negativeFeedback: 'Negative feedback',
      positiveFeedback: 'Positive feedback',
      score: 'Score',
      attribute: 'Attribute',
      suggestedValue: 'Suggested value',
      edgeServer: 'Edge server',
      name: 'Name',
      context: 'Context',
      eventID: 'Event ID',
      mark: 'Mark',
      ipAddress: 'IP address',
      messageType: 'Message type',
      facilityPoleId: 'Structure ID',
      circuitTlId: 'Circuit / Tie Line ID',
      circuitId: 'Circuit ID',
      tieLineId: 'Tie Line ID',
      facilityPoleIdPlaceholder: 'E.g., P789 or Z1011',
      circuitIdPlaceholder: 'E.g., 123/TL456',
      tieLineIdPlaceholder: 'E.g., TL456',
      assetId: 'Asset Id',
      sap: 'Sap',
      enterId: 'Enter ID',
      sapPlaceholder: 'Enter Sap',
      assetRegistryId: 'AssetRegistry Id',
      networkType: 'Subtype',
      poleID: 'Pole id',
      poleIDPlaceholder: 'Enter Pole id',
      hDamage: 'Human Labeled',
      mDamage: 'Machine Detection',
      detection: 'Detection',
      material: 'Material',
      structureMaterial: 'Structure Material',
      district: 'District',
      source: 'Source',
      captureType: 'Capture Type',
      status: 'Status',
      verificationStatus: 'Verification Status',
      type: 'Type',
      area: 'Area',
      photos: 'Photos',
      lidarFiles: 'LiDAR Files',
      lidarView: 'LiDAR View',
      devices: 'Devices',
      assets: 'Assets',
      damages: 'Damages',
      poles: 'Poles',
      reviewedDamaged: 'Verified damaged',
      verifiedHealthy: 'Verified healthy',
      predictedDamaged: 'Predicted damaged',
      predictedHealthy: 'Predicted healthy',
      noPrediction: 'No prediction',
      predicted: 'Predicted',
      verified: 'Verified',
      verifiedHigh: 'Verified High Issue',
      verifiedMedium: 'Verified Medium Issue',
      verifiedLow: 'Verified Low Issue',
      verifiedMediumLow: 'Verified Medium and Low Issues',
      complete: 'Complete',
      inProgress: 'In Progress',
      notStarted: 'Not Started',
      nA: 'N/A',
      rejected: 'Rejected',
      mapKey: 'Map Key',
      accessLevel: 'Access level',
      description: 'Description',
      attachmentLevel: 'Attachment Level',
      attachments: 'Attachments',
      messengerClamp: 'Messenger Clamp',
      providersIdentified: 'Providers Identified',
      cipTag: 'CIP Tag',
      terminalTag: 'Terminal Tag',
      successEditItem: 'Success edit item',
      editItemFailed: 'Edit item failed',
      defineOnMap: 'Define shape directly on the map',
      seeComputedData: 'See computed data',
      iconUrl: 'Icon URL',
      subtype: 'Subtype',
      dontForgetToSave: "Don't forget to save your changes before you leave",
      add: 'Add',
      saveQuit: 'Save & Quit',
      assetType: 'Asset Type',
      damageType: 'Damage Type',
      damagedArea: 'Damaged Area',
      conditionCode: 'Condition Code',
      predictionConfidence: 'Prediction Confidence',
      modelRevision: ' Model Revision',
      dateImageCaptured: ' Date Image Captured',
      dateML: 'Date of ML Assessment',
      severity: 'Severity',
      addDamageTitle: 'Add new damage',
      close: 'Close',
      Type: 'Type',
      selectRegion: 'Select area in image',
      fireDistricts: 'Show fire threat districts',
      itemPoles: 'Number of Poles',
      anyDamageSelectOption: 'Any damage',
      filteredData: 'The displayed data is filtered.',
      lidarfile: 'Enter Lidar File ID',
      selectInfrastructure: 'Select Infrastructure',
      backToFilters: 'Back to Filters',
      showPolesInArea: 'Search this area',
      searchPolesInArea: 'Search current map area',
      moreFilterCriteriaNeeded:
        'Set more filter criteria or turn on "Search current map area" checkbox.',
      turnOnGeoTooltip:
        'Turn on "Search current map area" checkbox in Filter to search in specific area.',
      damaged: 'Damaged',
      assetDamaged: 'Asset Damaged?',
      healthy: 'Healthy',
      newBBox: 'New bounding box',
      addNewDamage: 'Add New Damage?',
      reset: 'Reset',
      export: 'Export',
      loadDistrictsFailed: 'Load districts failed',
      loadAssetsFailed: 'Load assets failed',
      loadProvidersFailed: 'Load providers failed',
      loadPoleItemFailed: 'Load pole item failed',
      assetReviewFailed: 'Asset review request failed',
      addDamageFailed: 'Add damage Failed',
      sendFeedbackFailed: 'Send feedback Failed',
      loadPoleItemsFailed: 'Load pole list failed',
      verifyDamage: 'Verify damage',
      rejectDamage: 'Reject damage',
      reviewAsset: 'Review Asset',
      assetReview: 'Asset Review',
      assetInformation: 'Asset Information',
      verifiedDiarIssue: 'Verified DIAR Issue',
      verifiedNonDiarIssue: 'Verified Non-DIAR Issue',
      rejectedIssue: 'Rejected issue',
      verifyDiarIssue: 'Verify DIAR Issue',
      verifyNonDiarIssue: 'Verify Non-DIAR Issue',
      rejectIssue: 'Reject issue',
      detectedBy: 'Detected by',
      reviewDate: 'Review date',
      poleLocation: 'Pole Location',
      location: 'Location',
      shotAngle: 'Shot Angle',
      labeling: 'Labeling',
      imageTools: 'Image Tools',
      contrast: 'Contrast',
      brightness: 'Brightness',
      zoom: 'Zoom',
      zoomIn: 'Zoom In',
      zoomOut: 'Zoom Out',
      detectionDate: 'Detection date',
      provider: 'Provider',
      providers: 'Providers',
      wireLevel: 'Wire Level',
      wireLevels: 'Wire Levels',
      enterNumber: 'Enter Number',
      noValue: 'n/a',
      discrepanciesPredicted: 'Predicted Discrepancies',
      matchesPredicted: 'Predicted Matches',
      discrepanciesVerified: 'Verified Discrepancies',
      matchesVerified: 'Verified Matches',
      matches: 'Matches',
      discrepancies: 'Discrepancies',
      unknownAngleClassification: 'Unknown angle classification',
      predictedMatch: 'Predicted Match',
      verifiedMatch: 'Verified Match',
      predictedDiscrepancy: 'Predicted Discrepancy',
      verifiedDiar: 'Verified DIAR Issues',
      verifiedNonDiar: 'Verified NON-DIAR Issues',
      predictedDiscrepancies: 'Predicted Discrepancies',
      qaqcReportExport: 'QAQC Report Export',
      qaqcExportTitle: 'Export the latest QAQC discrepancies data',
      loadQaqcExportFailed: 'Load QAQC Export Failed',
      photoEvidence: 'Photo Evidence',
      upstream: 'Upstream',
      downstream: 'Downstream',
      level: 'Level',
      otherPhotos: 'Other Photos',
      sapTitle: 'SAP',
      iip: 'IIP',
      discrepancy: 'Discrepancy',
      removeProvider: 'Remove Provider',
      dragProvider: 'Drag Provider',
      providerPlaceholder: 'Select a Provider',
      cmpInspectionIssues: 'RIDI Inspection Issues',
      cmpCode: 'RIDI Code',
      issueDescription: 'Issue Description',
      verify: 'Verify',
      reject: 'Reject',
      updated: 'Updated',
      required: 'Required',
      optional: 'Optional',
      reviewed: 'Reviewed',
      onHold: 'On Hold',
      onHoldState: 'On Hold - State',
      onHoldMapKey: '(Example of marked On Hold pole)',
      specificEquipment: 'Specific Equipment',
      loadWsdExportFailed: 'Load Wsd Export Failed',
      wsdGetUrlFailed: 'Load Wsd Url Failed',
      date: 'Date',
      deleteAssetFailed: 'Delete Asset Failed',
      confirmPoleFailed: 'Pole Verification Failed',
      editAttachmentsFailed: 'Edit Attachments Failed',
      pointCloudView: 'Lidar view',
      vendor: 'Vendor',
      lidarFile: 'Lidar File ID',
      addAssetFailed: "Specified provider doesn't match",
      flagFailed: 'Flagging for follow up failed',
      assesment: 'Assessment Approval Date',
      sempraDisclaimer: 'San Diego Gas & Electric',
      addNewProvider: 'Add New Provider',
      imageInfo: 'Image Info',
      imageInformation: 'Image Information',
      downloadPhoto: 'Download Photo',
      downloadPhotos: 'Download Photos',
      imagesProcessing: 'Images Processing',
      editingModalConfirmation: 'Are you sure you want to flag these photos for follow up?',
      editPhotos: 'Edit Photos',
      flag: 'Flag',
      removeFlag: 'Remove Flag',
      singleImageLabel: 'Do you want to download photo in:',
      multipleImagesLabel: 'Do you want to download photos in:',
      highResolution: 'High Resolution',
      lowerResolution: 'Lower Resolution',
      processingStarted: 'Processing started',
      processingFinished: 'Processing Finished',
      imageDownloadFailed: 'Image download failed',
      approximateFileSize: 'Approximate File Size',
      download: 'Download',
      dataOwner: 'Data Owner',
      dataSource: 'Data Source',
      uploadedBy: 'Uploaded By',
      lineType: 'Line Type',
      coordinateSystem: 'Coordinate System',
      imageSource: 'Image Source',
      captureDate: 'Capture Date',
      uploadDate: 'Upload Date',
      filter: 'Filter',
      tier: 'Tier',
      tier1: 'Non-HFTD',
      tier2: 'Tier 2',
      tier3: 'Tier 3',
      simplified: 'Simplified',
      detailed: 'Detailed',
      dateRange: 'Date Range',
      capturedDateRange: 'Captured Date Range',
      from: 'From',
      to: 'To',
      loadPoleCoverageFailed: 'Load Pole Coverage Failed',
      coverageTitle: 'District Coverage Statistics',
      totalCoverage: 'Total Coverage',
      totalPoles: 'Total Poles',
      coveredPoles: 'Covered Poles',
      notCoveredPoles: 'Not Covered Poles',
      heatMapFilter: 'Heat Map Filter',
      mapLidarPolygons: 'Map LiDAR Polygons',
      verifySeverity: 'Verify issue based on severity',
      highSeverity: 'High Severity',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      lowSeverity: 'Low Severity',
      collapseAll: 'Collapse All',
      expandAll: 'Expand All',
      configuration: 'Configuration',
      poleStatus: 'Pole Status',
      modelStatus: 'Model Status',
      showArchivedPhotos: 'Show Archived Photos',
      hideArchivedPhotos: 'Hide Photos',
      infrastructure: 'Infrastructure',
      distribution: 'Distribution',
      transmission: 'Transmission',
      surface: 'Surface',
      subsurface: 'Subsurface',
      noContent: 'No Results Found',
      tooManyResults: 'Too Many Results',
      search: 'Search',
      collapse: 'Collapse',
      exampleStructure: 'Ex: P971107',
      exampleCircuit: 'Ex: 1250',
      exampleLidar: 'Ex: L4197',
      qaqc: {
        assessmentReport: 'Assessment Report'
      }
    },
    reports: {
      sendMarkSuccess: 'Update user details was successful!',
      sendMarkFailed: 'Send  mark failed'
    },
    uas: {
      import: 'Import',
      upload: 'Upload',
      reupload: 'Reupload',
      cancel: 'Cancel',
      uploadFiles: 'Upload Files',
      uploadRadioLabel: 'Do you want to upload using:',
      simpleImageUpload: 'Simple Image Upload',
      simpleImageUploadDesc: 'Ideal for imagery',
      complexDataUpload: 'Complex Data Upload',
      complexDataUploadDesc1: 'Necessary to fill out a data form',
      complexDataUploadDesc2: 'Ideal for LiDAR',
      dataSetType: 'DataSet Type',
      dataOwner: 'Data Owner',
      imageSource: 'Image Source',
      structureType: 'Structure Type',
      structureId: 'Structure ID',
      source: 'Source',
      lineType: 'Line Type',
      geodeticCoordinateSystem: 'Geodetic Coordinate System',
      circuitTieLine: 'Circuit / Tie Line',
      captureType: 'Capture Type',
      captureDate: 'Capture Date',
      comment: 'Comment',
      draggerUploadText: 'Click or drag a file to this area to upload',
      draggerUploadTextAllowed:
        'Allowed types of files: .atx, .bak, .cpg, .csv, .dbf, .doc, .docx, .dxf, .ecw, .gdbindexes, .gdbtable, .jpeg, .jpg, .json, .kml, .kmz, .las, .laz, .pdf, .png, .prj, .rar, .shp, .shx, .spx, .tfw, .txt, .xls, .xlsx, .xml, .zip',
      allowedTypes:
        '.atx,.ATX,.bak,.BAK,.cpg,.CPG,.csv,.CSV,.dbf,.DBF,.doc,.DOC,.docx,.DOCX,.dxf,.DXF,.ecw,.ECW,.gdbindexes,.GDBINDEXES,.gdbtable,.GDBTABLE,.jpeg,.JPEG,.jpg,.JPG,.json,.JSON,.kml,.KML,.kmz,.KMZ,.las,.LAS,.laz,.LAZ,.pdf,.PDF,.png,.PNG,.prj,.PRJ,.rar,.RAR,.shp,.SHP,.shx,.SHX,.spx,.SPX,.tfw,.TFW,.txt,.TXT,.xls,.XLS,.xlsx,.XLSX,.xml,.XML,.zip,.ZIP',
      file: 'file',
      files: 'files',
      addMoreFiles: 'Add more files',
      uploadingFiles: 'Uploading files...',
      successfullyUploaded: 'Successfully Uploaded',
      errorUploading: 'There was an error in uploading some files',
      invalidStructureId: 'Provided structure ID does not exist',
      existingUploading: 'Files already exist',
      unsupportedFileType: 'Unsupported file type'
    },
    map: {
      mapKey: 'Map Key',
      filterMenu: 'Filter Menu',
      openSmallMap: 'Expand Mini Map',
      closeSmallMap: 'Collapse Mini Map'
    },
    languages: {
      'en-US': 'EN',
      'sk-SK': 'SK'
    }
  }
};
const strings = new LocalizedStrings(lang);
export default strings;
