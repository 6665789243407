export const HEATMAP_SOURCE_ID = 'heat-map-source';
export const HEATMAP_ID = 'poles_heatmap';

export const heatMapMetadata = {
  layerId: HEATMAP_ID,
  name: 'Heat Map View'
};

const MAX_ZOOM_LEVEL = 24;
const heatmapLayer = {
  id: HEATMAP_ID,
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(0,0,255,0)',
      0.01,
      'rgb(65,105,225)',
      0.3,
      'rgb(0,255,255)',
      0.5,
      'rgb(0,255,0)',
      0.7,
      'rgb(255,255,0)',
      1,
      'rgb(255,0,0)'
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 38, MAX_ZOOM_LEVEL, 1],
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 0]
  }
};
export default heatmapLayer;
