// TODO: refactor
// temporary moved from actions file to avoid renaming actions file
import React from 'react';
import Icon from '@ant-design/icons';
import cross from '../../images/cross.svg';
import circle from '../../images/circle.svg';

export const noTransmissionAccess = {
  closable: true,
  closeIcon: (
    <Icon
      className="cross-transmission"
      component={() => <img src={cross} alt="cross-transmission" />}
    />
  ),
  icon: (
    <Icon
      className="circle-transmission"
      component={() => <img src={circle} alt="circle-transmission" />}
    />
  ),
  className: 'transmissionModal',
  title: 'Missing Access Permission',
  content: <>This information is only available to users with access to Transmission data. To get access, please complete the GISPC200 Electric GIS Data Policy, using:
      <ul><li><a href="https://sempra.csod.com/ui/lms-learning-details/app/course/1f3e833d-8ac4-40aa-a5f2-36100fc8a347">MyLearning link</a> for FTEs</li><li><a href="https://contractortraining.sempra.com/course">Contractor Training link</a> for contractors (search for &quot;GIS Electric Data Policy&quot;)</li></ul></>
  
};

export default { noTransmissionAccess };
