import { TABS } from '../../layout/navigation/config';
import { STRUCTURES_LAYER_ID, CIP_LAYER_ID, QAQC_LAYER_ID } from './layers/constants';
import { structuresLayer as structuresLayerConfig } from './layers/StructuresLayer/structures-styles';
import { cipLayer as cipLayerConfig } from './layers/CIPLayer/cip-styles';
import { qaqcLayer as qaqcLayerConfig } from './layers/QAQCLayer/qaqc-styles';

export const urlIsNotEmpty = url => url && url !== '';

export const calculateBoundsAndCenter = locations => {
  const latitudes = locations.map(location => location[0]);
  const longitudes = locations.map(location => location[1]);

  const latitudeMax = Math.max(...latitudes);
  const longitudeMax = Math.max(...longitudes);
  const latitudeMin = Math.min(...latitudes);
  const longitudeMin = Math.min(...longitudes);

  const center = [(latitudeMin + latitudeMax) / 2, (longitudeMin + longitudeMax) / 2];

  return {
    center,
    bounds: [
      [latitudeMin, longitudeMax],
      [latitudeMax, longitudeMin]
    ],
    width: latitudeMax - latitudeMin,
    height: longitudeMax - longitudeMin
  };
};

/**
 *
 * from https://stackoverflow.com/a/57578003
 * @param  {} long
 * @param  {} lat
 * @param  {} zoom
 */
export const pixelsFromLatLong = (lat, long, zoom) => {
  const project = (latitude, longitude) => {
    const TILE_SIZE = 200;
    let siny = Math.sin((latitude * Math.PI) / 180);

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999);

    return {
      x: TILE_SIZE * (0.5 + longitude / 360),
      y: TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
    };
  };

  // Get the world coordinates in pixels
  const worldCoordinate = project(lat, long);
  // Scale to fit our image
  const scale = 2 ** zoom;

  // Apply scale to world coordinates to get image coordinates
  return {
    x: Math.floor(worldCoordinate.x * scale),
    y: Math.floor(worldCoordinate.y * scale)
  };
};

/**
 * Checks if data should be loaded for given tab and zoom level
 * @param  {string} openedTab
 * @param  {number} newZoom
 * @param  {number} existingZoom
 */
// eslint-disable-next-line no-unused-vars
export const shouldEnableDataLoad = (openedTab, newZoom, existingZoom) => {
  let minZoom;
  switch (openedTab) {
    case TABS.QAQC:
      minZoom = qaqcLayerConfig.minzoom;
      break;
    case TABS.CIP:
      minZoom = cipLayerConfig.minzoom;
      break;
    case TABS.STRUCTURES:
    default:
      minZoom = structuresLayerConfig.minzoom;
  }
  if (newZoom < minZoom) {
    return false;
  }
  return true;
  // MAPBOX imporovement: check also if any data were loaded and enable check bellow
  // data were loaded for bigger area
  // return newZoom < existingZoom;
};

export const mapTabToLayer = tab => {
  switch (tab) {
    case TABS.CIP:
      return CIP_LAYER_ID;
    case TABS.QAQC:
      return QAQC_LAYER_ID;
    case TABS.STRUCTURES:
    default:
      return STRUCTURES_LAYER_ID;
  }
};

// TODO: use the one from package later
export const getSelectedFeatureByLayer = (state, layer) => {
  if (state.map.selectedFeature && state.map.selectedFeature.metadata?.layer === layer) {
    return state.map.selectedFeature;
  }
  return null;
};
