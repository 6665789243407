import {
  SET_SIDEBAR_MODE,
  SET_SIDEBAR_COLLAPSED,
  SET_SIDEBAR_SCROLL_POSITION
} from '../../redux/constants/actionTypes';

import { MODES } from './constants';


export const setSidebarMode = mode => ({
  type: SET_SIDEBAR_MODE,
  payload: mode
});

export const setSidebarCollapsed = collapsed => ({
  type: SET_SIDEBAR_COLLAPSED,
  payload: collapsed
});

export const setSidebarScrollPosition = position => ({
  type: SET_SIDEBAR_SCROLL_POSITION,
  payload: position
});

export const openSidebarDetail = () => dispatch => {
  dispatch(setSidebarMode(MODES.DETAIL));
  dispatch(setSidebarCollapsed(false));
};
