import NoPredictionIcon from '../CIPLayer/noprediction.png';
import PredictedDiscrepanciesIcon from '../CIPLayer/predictedDiscrepancies.png';
import PredictedMatchesIcon from '../CIPLayer/predictedMatches.png';
import VerifiedDiscrepanciesIcon from '../CIPLayer/verifiedDiscrepancies.png';
import VerifiedMatchesIcon from '../CIPLayer/verifiedMatches.png';
import FlagIcon from './flag.png';
import ExclamationIcon from './exclamation.png';

export const QAQC_SOURCE_ID = 'qaqc_source';
export const QAQC_LAYER_ID = 'qaqc';

export const qaqcLayerMetadata = {
  layerId: QAQC_LAYER_ID,
  name: 'QAQC',
  layerSymbols: [
    {
      symbolName: 'qaqcNoPredictionIcon',
      symbol: NoPredictionIcon
    },
    { symbolName: 'qaqcPredictedDiscrepanciesIcon', symbol: PredictedDiscrepanciesIcon },
    { symbolName: 'qaqcPredictedMatchesIcon', symbol: PredictedMatchesIcon },
    { symbolName: 'qaqcVerifiedDiscrepanciesIcon', symbol: VerifiedDiscrepanciesIcon },
    { symbolName: 'qaqcVerifiedMatchesIcon', symbol: VerifiedMatchesIcon },
    { symbolName: 'LowMediumIssueIcon', symbol: FlagIcon },
    { symbolName: 'HighIssueIcon', symbol: ExclamationIcon }
  ]
};

export const qaqcLayer = {
  id: QAQC_LAYER_ID,
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'Status'], 0],
      'NoPredictionIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_PREDICTED'],
      'PredictedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 8],
      'PredictedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_PREDICTED'],
      'PredictedMatchesIcon',
      ['==', ['get', 'Status'], 6],
      'PredictedMatchesIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_VERIFIED'],
      'VerifiedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_VERIFIED'],
      'VerifiedMatchesIcon',
      ['==', ['get', 'Status'], 7],
      'VerifiedMatchesIcon',
      ['==', ['get', 'Status'], 10],
      'LowMediumIssueIcon',
      ['==', ['get', 'Status'], 12],
      'LowMediumIssueIcon',
      ['==', ['get', 'Status'], 9],
      'HighIssueIcon',
      'black'
    ],
    'icon-size': 0.4,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': [
      'case',
      [
        '!',
        [
          'in',
          ['get', 'Status'],
          [
            'literal',
            [
              0,
              8,
              6,
              7,
              10,
              12,
              9,
              'DISCREPANCIES_PREDICTED',
              'MATCHES_PREDICTED',
              'DISCREPANCIES_VERIFIED',
              'MATCHES_VERIFIED'
            ]
          ]
        ]
      ],
      '',
      ['==', ['get', 'structureIdToggle'], true],
      ['get', 'FacilityId'],
      ''
    ],
    'text-size': 12,
    'text-variable-anchor': ['bottom-left'],
    'text-radial-offset': 1.25,
    'text-justify': 'auto'
  },
  minzoom: 10
};

export const QAQC_CLICKED_SOURCE_ID = 'aqc_clicked_source';
export const QAQC_CLICKED_LAYER_ID = 'qaqc_clicked';

export const qaqcClickedLayer = {
  id: QAQC_CLICKED_LAYER_ID,
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'Status'], 0],
      'NoPredictionIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_PREDICTED'],
      'PredictedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 8],
      'PredictedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_PREDICTED'],
      'PredictedMatchesIcon',
      ['==', ['get', 'Status'], 6],
      'PredictedMatchesIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_VERIFIED'],
      'VerifiedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_VERIFIED'],
      'VerifiedMatchesIcon',
      ['==', ['get', 'Status'], 7],
      'VerifiedMatchesIcon',
      ['==', ['get', 'Status'], 10],
      'LowMediumIssueIcon',
      ['==', ['get', 'Status'], 12],
      'LowMediumIssueIcon',
      ['==', ['get', 'Status'], 9],
      'HighIssueIcon',
      'black'
    ],
    'icon-size': 0.8,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': [
      'case',
      [
        '!',
        [
          'in',
          ['get', 'Status'],
          [
            'literal',
            [
              0,
              8,
              6,
              7,
              10,
              12,
              9,
              'DISCREPANCIES_PREDICTED',
              'MATCHES_PREDICTED',
              'DISCREPANCIES_VERIFIED',
              'MATCHES_VERIFIED'
            ]
          ]
        ]
      ],
      '',
      ['==', ['get', 'structureIdToggle'], true],
      ['get', 'FacilityId'],
      ''
    ],
    'text-size': 12,
    'text-variable-anchor': ['bottom-left'],
    'text-radial-offset': 1.25,
    'text-justify': 'auto'
  },
  minzoom: 10
}

export const activeLayoutChanges = { 'icon-size': 0.8 };
