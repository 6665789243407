import * as _ from 'underscore';
import DOMPurify from 'dompurify';
import { message, Modal } from 'antd';
import axios from 'axios';
import AxiosError from 'axios-error';
import moment from 'moment';
import { addActiveLayer } from '@innovation-toolkit/mapbox';
import { mapActions } from '@innovation-toolkit/iip-ui';

import {
  SET_INFRASTRUCTURE_ITEMS,
  SET_IDS_TOGGLE,
  SET_SELECTED_ITEM,
  SET_NEW_ITEM_FORM_VALUES,
  RESET_NEW_ITEM_FORM,
  SET_SECONDARY_CREATE_FLAG,
  SET_CHILD_ITEM_FLAG,
  SET_LOCAL_LOADERS,
  SET_INFRASTRUCTURE_FILTERS,
  ADD_NEW_DAMAGE,
  SET_BOUNDING_BOX_EDIT_OFF,
  SET_BOUNDING_BOX_EDIT_ON,
  SET_BUCKET,
  SET_DISTRICTS,
  SET_LIDAR_POLYGONS,
  SET_LIDAR_DISTRICTS_HIDDEN,
  HIDE_BIG_IMAGE,
  SHOW_BIG_IMAGE,
  UNSET_POLE_DETAIL,
  SHOW_SMALL_IMAGE,
  HIDE_SMALL_IMAGE,
  SET_SMALL_IMAGE_INDEX,
  SET_FILTER_VISIBILITY,
  SET_BOUNDING_BOX_VISIBILITY,
  SET_IMAGEURL_MAP,
  LOAD_ASSETS,
  LOAD_PROVIDERS,
  SET_ACTIVE_DAMAGE,
  SET_CURRENT_IMAGE,
  RESET_INFRASTRUCTURE_FILTERS,
  RESET_ACTIVE_DAMAGE,
  SET_FOCUS_ON_ITEMS,
  RESET_FOCUS_ON_ITEMS,
  NEGATIVE_FEEDBACK_PANEL,
  SET_PROVIDERS_BY_LEVEL,
  SET_IS_AUTOMATIC_SWITCH_FLAG,
  SET_QAQC_EXPORT_FILE,
  SET_QAQC_EXPORT_FINISHED,
  SET_PHOTO_EVIDENCE_FOR_DELETION,
  RESET_PHOTO_EVIDENCE_FOR_DELETION,
  SET_CIP_DELETED_ANNOTATION,
  SET_LIDAR_FILES_FOR_SELECTED_POLE,
  SET_SIDEBAR_SPECIAL_CSS,
  SET_SMALL_MAP_COLLAPSE,
  SET_ADD_NEW_PROVIDER,
  SET_SELECTED_IMAGE,
  SET_DOWNLOAD_MODAL_OPEN,
  SET_DOWNLOAD_PROGRESS_OPEN,
  SET_IMAGES_FOR_DOWNLOAD,
  SET_IMAGE_COUNT_DOWNLOADING,
  SET_IMAGE_DOWNLOADING_DONE,
  TOGGLE_IMAGE_TOOLS_PANEL,
  TOGGLE_LABELING_TOOLS_PANEL,
  SET_POLE_COVERAGE,
  UPDATE_IMAGE_BRIGHTNESS,
  UPDATE_IMAGE_CONTRAST,
  RESET_IMAGE_FILTERS,
  SET_ZOOMED_POLE,
  SET_HOVERED_LIDAR,
  SET_SELECTED_ASSET_GROUPING_INDEX,
  SET_CHECKED_BB,
  SET_CHECKED_ALL,
  SET_WARNING_MESSAGE,
  SET_POLE_DETAIL,
  LOAD_USER_UPLOAD_DROPDOWNS
} from '../../redux/constants/actionTypes';
import PERMISSIONS from '../../auth.config';
import { currentUserHasPermission } from '../utils/roleUtils';
import strings from '../../translate/strings.js';
import { arangoUuid } from '../../utils/vasp-utils';
import messages from '../../utils/messages';
import { TABS } from '../../layout/navigation/config';
import { MODES } from '../sidebar/constants';

import { LOADERS, IMAGE_STATUS } from '../utils/constants';
import providers from './poles/resources/cipProviders.json';
import { imageSize } from './constants';
import { LIDAR_LAYER_ID, STRUCTURES_LAYER_ID } from '../map/layers/constants';
import { noTransmissionAccess } from './jsxForActions.jsx';
import { openSidebarDetail } from '../sidebar/actions';
import { mapTabToLayer } from '../map/utils';
import { updateDamageStatus, updateDiscrepancy} from './actions_utils';

const {
  POLES_DAMAGES_RW,
  POLES_DAMAGES_R,
  POLES_ASSETS_RW,
  POLES_ASSETS_R,
  CIP_R,
  CIP_RW,
  TRANSMISSION_ACCESS_R,
  TRANSMISSION_ACCESS_RW
} = PERMISSIONS;

const tooManyPolesModal = {
  title: 'Too Many Results',
  content:
    ' Too many structures were returned based on your filter criteria. Please narrow down your criteria by selecting more filter options or zooming in.'
};

const noResultsModal = {
  title: 'No Results Found',
  content:
    ' No structures were returned based on your filter criteria. Please widen your criteria by selecting less filter options or zooming out.'
};

let cancel;

export const createLocalLoaders = dispatch => (isLoading, loadersArr) =>
  dispatch({
    type: SET_LOCAL_LOADERS,
    payload: {
      names: loadersArr,
      isLoading
    }
  });

const processItems = items => async () =>
  // load settings if necessary - if they are not defined

  items.map(item => {
    let DamageCount;
    let PictureCount;

    if (item.DamageCount) {
      DamageCount = item.DamageCount;
    } else {
      DamageCount = item.Damages ? item.Damages.length : 0;
    }

    if (item.PictureCount) {
      PictureCount = item.PictureCount;
    } else {
      PictureCount =
        item.PictureBatches && item.PictureBatches[0] && item.PictureBatches[0].PictureRefs
          ? item.PictureBatches[0].PictureRefs.length.toString()
          : '0';
    }
    const newItem = {
      ...item,
      DamageCount,
      PictureCount,
      arangoUuid: arangoUuid(item)
    };

    return newItem;
  });

export const setInfrastructureFilters = newFilters => dispatch => {
  dispatch({ type: SET_INFRASTRUCTURE_FILTERS, payload: newFilters });
};

export const resetItems = () => dispatch => {
  dispatch({ type: SET_INFRASTRUCTURE_ITEMS, payload: null });
  dispatch({ type: RESET_INFRASTRUCTURE_FILTERS });
};

// MAPBOX TODO: refactor
/**
 * Fetching structures with respective filters applied
 */
export const loadStructureLayerItems = (focus, filter) => async (dispatch, getState) => {
  const filters = filter === undefined ? getState().infrastructure.filters : filter;

  const { mapBounds } = getState().map;

  const { poleCoverage } = getState().infrastructure;
  const setLocalLoaders = createLocalLoaders(dispatch);
  if (cancel) {
    cancel();
    setLocalLoaders(false, [LOADERS.POLES]);
  }

  setLocalLoaders(true, [LOADERS.POLES]);

  let urlEnd = '';
  let url = `${getState().main.dynamicConfig.APIGateway}${urlEnd}`;
  if (filters.facilityId !== '') {
    urlEnd = `pole?FacilityId=${filters.facilityId}`;
    url = `${getState().main.dynamicConfig.APIGateway}${urlEnd}`;
  } else if (filters.lidarFile !== '') {
    urlEnd = 'clusters';
    url = `${getState().main.dynamicConfig.APIGateway}${urlEnd}?lidarFileName=${filters.lidarFile}`;
  } else {
    let filterURL = '';
    if (filters.district !== '') {
      filterURL = `${filterURL}&district=${filters.district}`;
    }

    if (filters.detection !== '') {
      filterURL = `${filterURL}&source=${filters.detection}`;
    }

    if (filters.assets.length > 0) {
      filterURL = `${filterURL}&assets=${filters.assets}`;
      if (filters.damages.length > 0) {
        filterURL = `${filterURL}&damages=${calculateClassId(
          filters,
          getState().infrastructure.assetTree
        )}`;
      }
    }

    if (filters.circuitTieLineId !== '') {
      filterURL = `${filterURL}&groupId=${filters.circuitTieLineId}`;
    }

    if (filters.tier !== '') {
      filterURL = `${filterURL}&tier=${filters.tier}`;
    }

    if (filters.material !== '') {
      filterURL = `${filterURL}&material=${filters.material}`;
    }

    if (filters.fireDistricts) {
      filterURL = `${filterURL}&firethread=${filters.fireDistricts}`;
    }

    if (filters.status && filters.status.length > 0) {
      filterURL = `${filterURL}&status=${filters.status}`;
    }

    if (filters.poleStatus && filters.poleStatus.length > 0) {
      filterURL = `${filterURL}&poleStatus=${filters.poleStatus}`;
    }

    if (filters.assetType && filters.assetType.length > 0) {
      filterURL = `${filterURL}&assetTypes=${filters.assetType}`;
    }

    if (filters.vendor && filters.vendor.length > 0) {
      filterURL = `${filterURL}&vendor=${filters.vendor}`;
    }

    if (filters.configuration && filters.configuration.length > 0) {
      filterURL = `${filterURL}&configuration=${filters.configuration}`;
    }

    if (filters.status && filters.status.length > 0) {
      filterURL = `${filterURL}&status=${filters.status}`;
    }

    if (filters.materialModel && filters.materialModel.length > 0) {
      filterURL = `${filterURL}&materialModel=${filters.materialModel}`;
    }

    if (filters.networkType) {
      filterURL = `${filterURL}&networkType=${encodeURIComponent(filters.networkType)}`;
    }

    if (filters.infrastructure && filters.infrastructure.length) {
      filterURL = `${filterURL}&infrastructureType=${encodeURIComponent(filters.infrastructure)}`;
    }

    const { searchWithGeoCoords } = filters;
    let urlParams = '';
    if (searchWithGeoCoords) {
      const geo = mapBounds;
      urlParams = `?minlat=${geo.minlat}&minlong=${geo.minlong}&maxlat=${geo.maxlat}&maxlong=${geo.maxlong}${filterURL}`;
    } else {
      /** substr because filterUrl starts with '&' char, but we need '?' in this case */
      urlParams = `?${filterURL.substr(1)}`;
    }
    urlEnd = 'clusters';
    url = `${getState().main.dynamicConfig.APIGateway}${urlEnd}${urlParams}`;
  }

  try {
    const response = await axios.get(url, {
      cancelToken: new axios.CancelToken(c => {
        cancel = c;
      })
    });

    const result = Array.isArray(response?.data) ? response.data : [response?.data];
    //  TODO: verify that this does not slow down rendering significantly
    // probably hard limit for leaflet
    // if (result.length > 1500) {
    //   Modal.info(tooManyPolesModal);
    //   dispatch({
    //     type: SET_WARNING_MESSAGE,
    //     payload: strings.infrastructure.tooManyResults
    //   });
    //   dispatch({
    //     type: SET_FILTER_VISIBILITY,
    //     payload: true
    //   });
    // } else
    if (result.length === 0) {
      Modal.info(noResultsModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.noContent
      });
    } else {
      const items = result.map(item => ({
        ...item,
        rangeKey: item.rangeKey ? item.rangeKey : `_${Math.random().toString(36).substr(2, 9)}`
      }));

      let allItems = await processItems([...items])(dispatch);

      if ((filters.facilityId !== '' || filters.circuitTieLineId !== '') && allItems.length > 0) {
        // TODO: figure out when and how is this information used
        dispatch({
          type: SET_ZOOMED_POLE,
          payload: allItems[0]
        });
      }

      if (poleCoverage && poleCoverage.length !== 0) {
        const poleCoverageIds = poleCoverage[0].coverage.map(pole => pole.id);
        allItems = allItems.map(item =>
          poleCoverageIds.includes(item.Id.toString())
            ? { ...item, Covered: false }
            : { ...item, Covered: true }
        );
      }
      dispatch({
        type: SET_INFRASTRUCTURE_ITEMS,
        payload: allItems
      });
      // switching layers to active state
      dispatch(addActiveLayer(STRUCTURES_LAYER_ID));

      // when results (at least 1) are returned from BE, hide filter if opened
      if (getState().infrastructure.isFilterVisible && result && result.length > 0) {
        dispatch({
          type: SET_FILTER_VISIBILITY,
          payload: false
        });
        if (focus) {
          dispatch({ type: SET_FOCUS_ON_ITEMS, payload: true });
        }
      }
    }
  } catch (e) {
    const axiosError = new AxiosError(e);
    dispatch({
      type: SET_INFRASTRUCTURE_ITEMS,
      payload: []
    });
    if (axiosError.request?.status === 413) {
      Modal.info(tooManyPolesModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.tooManyResults
      });
      dispatch({
        type: SET_FILTER_VISIBILITY,
        payload: true
      });
      setLocalLoaders(false, [LOADERS.POLES]);
    } else {
      if (axiosError.response?.status !== 404) {
        message.error(strings.infrastructure.loadPoleItemsFailed);
      }
      throw e;
    }
  } finally {
    setLocalLoaders(false, [LOADERS.POLES]);
  }
};

// MAPBOX TODO: decouple this function and trigger loads from respective layers
export const loadPoleItems = (focus, filter) => async (dispatch, getState) => {
  const filters = filter === undefined ? getState().infrastructure.filters : filter;

  const { mapBounds: bounds } = getState().map;
  const { tab } = getState().view;
  const { poleCoverage } = getState().infrastructure;
  const setLocalLoaders = createLocalLoaders(dispatch);
  if (cancel) {
    cancel();
    setLocalLoaders(false, [LOADERS.POLES]);
  }

  setLocalLoaders(true, [LOADERS.POLES]);

  let url = '';
  let urlEnd = '';
  if (filters.lidarFile !== '') {
    switch (tab) {
      case TABS.CIP:
        urlEnd = 'cip/poles';
        break;
      case TABS.QAQC:
        urlEnd = 'qaqc/poles';
        break;
      default:
        urlEnd = 'clusters';
    }
    url = `${getState().main.dynamicConfig.APIGateway}${urlEnd}?lidarFileName=${filters.lidarFile}`;
  } else {
    let filterURL = '';
    if (filters.district !== '') {
      filterURL = `${filterURL}&district=${filters.district}`;
    }

    if (filters.detection !== '') {
      filterURL = `${filterURL}&source=${filters.detection}`;
    }

    if (filters.assets.length > 0) {
      filterURL = `${filterURL}&assets=${filters.assets}`;
      if (filters.damages.length > 0) {
        filterURL = `${filterURL}&damages=${calculateClassId(
          filters,
          getState().infrastructure.assetTree
        )}`;
      }
    }

    if (filters.circuitTieLineId !== '') {
      filterURL = `${filterURL}&groupId=${filters.circuitTieLineId}`;
    }

    if (filters.tier !== '') {
      filterURL = `${filterURL}&tier=${filters.tier}`;
    }

    if (filters.material !== '') {
      filterURL = `${filterURL}&material=${filters.material}`;
    }

    if (filters.fireDistricts) {
      filterURL = `${filterURL}&firethread=${filters.fireDistricts}`;
    }

    if (filters.status && filters.status.length > 0) {
      filterURL = `${filterURL}&status=${filters.status}`;
    }

    if (filters.poleStatus && filters.poleStatus.length > 0) {
      filterURL = `${filterURL}&poleStatus=${filters.poleStatus}`;
    }

    if (filters.assetType && filters.assetType.length > 0) {
      filterURL = `${filterURL}&assetTypes=${filters.assetType}`;
    }

    if (filters.vendor && filters.vendor.length > 0) {
      filterURL = `${filterURL}&vendor=${filters.vendor}`;
    }

    if (filters.configuration && filters.configuration.length > 0) {
      filterURL = `${filterURL}&configuration=${filters.configuration}`;
    }

    if (filters.status && filters.status.length > 0) {
      filterURL = `${filterURL}&status=${filters.status}`;
    }

    if (filters.materialModel && filters.materialModel.length > 0) {
      filterURL = `${filterURL}&materialModel=${filters.materialModel}`;
    }

    if (filters.networkType) {
      filterURL = `${filterURL}&networkType=${encodeURIComponent(filters.networkType)}`;
    }

    if (filters.infrastructure && filters.infrastructure.length) {
      filterURL = `${filterURL}&infrastructureType=${encodeURIComponent(filters.infrastructure)}`;
    }

    // Cip special fields
    if (filters.provider.length > 0) {
      filterURL = `${filterURL}&providers=${encodeURIComponent(filters.provider)}`;
    }

    if (filters.wireLevel) {
      filterURL = `${filterURL}&wireLevel=${filters.wireLevel}`;
    }

    if (filters.holdStatus !== null) {
      filterURL = `${filterURL}&holdStatus=${filters.holdStatus}`;
    }

    const { searchWithGeoCoords } = filters;
    let urlParams = '';
    if (searchWithGeoCoords) {
      const geo = bounds;
      urlParams = `?minlat=${geo.minlat}&minlong=${geo.minlong}&maxlat=${geo.maxlat}&maxlong=${geo.maxlong}${filterURL}`;
    } else {
      /** substr because filterUrl starts with '&' char, but we need '?' in this case */
      urlParams = `?${filterURL.substr(1)}`;
    }

    switch (tab) {
      case TABS.CIP:
        urlEnd = 'cip/poles';
        break;
      case TABS.QAQC:
        urlEnd = 'qaqc/poles';
        break;
      default:
        urlEnd = 'clusters';
    }
    url = `${getState().main.dynamicConfig.APIGateway}${urlEnd}${urlParams}`;
  }

  try {
    const response = await axios.get(url, {
      cancelToken: new axios.CancelToken(c => {
        cancel = c;
      })
    });

    const result = Array.isArray(response?.data) ? response.data : [response?.data];
    // TODO explained in fnc above
    // if (result.length > 1500) {
    //   Modal.info(tooManyPolesModal);
    //   dispatch({
    //     type: SET_WARNING_MESSAGE,
    //     payload: strings.infrastructure.tooManyResults
    //   });
    //   dispatch({
    //     type: SET_FILTER_VISIBILITY,
    //     payload: true
    //   });
    // } else
    if (result.length === 0) {
      Modal.info(noResultsModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.noContent
      });
    } else {
      const items = result.map(item => ({
        ...item,
        rangeKey: item.rangeKey ? item.rangeKey : `_${Math.random().toString(36).substr(2, 9)}`
      }));

      let allItems = await processItems([...items])(dispatch);
      // TODO: look into the last condition
      if (
        tab === TABS.CIP &&
        filters.facilityId !== '' &&
        allItems.length === 1 &&
        typeof allItems[0].NoOfWireLevelsIIP === 'undefined'
      ) {
        allItems[0].NoOfWireLevelsIIP = allItems[0].Providers.reduce((count, provider) => {
          let newCount = count;
          provider.WireLevelsIIP.forEach(() => {
            newCount += 1;
          });
          return newCount;
        }, 0);
        allItems[0].NoOfWireLevelsSAP = allItems[0].Providers.length;
      }

      if ((filters.facilityId !== '' || filters.circuitTieLineId !== '') && allItems.length > 0) {
        dispatch({
          type: SET_ZOOMED_POLE,
          payload: allItems[0]
        });
      }

      if (poleCoverage && poleCoverage.length !== 0) {
        const poleCoverageIds = poleCoverage[0].coverage.map(pole => pole.id);
        allItems = allItems.map(item =>
          poleCoverageIds.includes(item.Id.toString())
            ? { ...item, Covered: false }
            : { ...item, Covered: true }
        );
      }

      dispatch({
        type: SET_INFRASTRUCTURE_ITEMS,
        payload: allItems
      });

      // when results (at least 1) are returned from BE, hide filter if opened
      if (getState().infrastructure.isFilterVisible && result && result.length > 0) {
        dispatch({
          type: SET_FILTER_VISIBILITY,
          payload: false
        });
        if (focus) {
          dispatch({ type: SET_FOCUS_ON_ITEMS, payload: true });
        }
      }
    }
  } catch (e) {
    const axiosError = new AxiosError(e);
    dispatch({
      type: SET_INFRASTRUCTURE_ITEMS,
      payload: []
    });
    if (axiosError.request?.status === 413) {
      Modal.info(tooManyPolesModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.tooManyResults
      });
      dispatch({
        type: SET_FILTER_VISIBILITY,
        payload: true
      });
      setLocalLoaders(false, [LOADERS.POLES]);
    } else {
      if (axiosError.response?.status !== 404) {
        message.error(strings.infrastructure.loadPoleItemsFailed);
      }
      throw e;
    }
  } finally {
    setLocalLoaders(false, [LOADERS.POLES]);
  }
};

export const loadLidarPolygons = (bounds, dateFrom, dateTo) => async (dispatch, getState) => {
  dispatch({
    type: SET_LOCAL_LOADERS,
    payload: {
      names: [LOADERS.MAP],
      isLoading: true
    }
  });
  const { lidarFile } = getState().infrastructure.filters;

  const fileUrl = `${
    getState().main.dynamicConfig.APIGateway
  }districts?type=lidar&lidarFile=${lidarFile}`;

  const standardUrl = `${getState().main.dynamicConfig.APIGateway}districts?type=lidar${
    dateFrom ? `&captureDateFrom=${dateFrom}` : ''
  }${dateTo ? `&captureDateTo=${dateTo}` : ''}${
    bounds
      ? `&&bbox=${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`
      : ''
  }`;

  const url = lidarFile ? fileUrl : standardUrl;
  try {
    const response = await axios.get(url);

    const allItems = await processItems([...response.data])(dispatch);

    dispatch({
      type: SET_LIDAR_POLYGONS,
      payload: allItems.length > 0 ? allItems : []
    });

    dispatch({
      type: SET_LOCAL_LOADERS,
      payload: {
        names: [LOADERS.MAP],
        isLoading: false
      }
    });
  } catch (e) {
    message.error(strings.infrastructure.loadDistrictsFailed);
    dispatch({
      type: SET_DISTRICTS,
      payload: []
    });
    dispatch({
      type: SET_LOCAL_LOADERS,
      payload: {
        names: [LOADERS.MAP],
        isLoading: false
      }
    });
    throw e;
  }
};

export const resetLidarPolygons = () => async dispatch => {
  dispatch({
    type: SET_LIDAR_POLYGONS,
    payload: []
  });
};

export const loadLidarPolygon = id => async (dispatch, getState) => {
  // polygon is already in lidarPolygons object, and since no new data are required for detail, set the one
  const existingLidarPolygons = getState().infrastructure.lidarPolygons;

  if (existingLidarPolygons && existingLidarPolygons[id]) {
    dispatch(
      mapActions.setSelectedFeature({
        id: 1,
        geometry: existingLidarPolygons[id].LocationPolygon,
        properties: existingLidarPolygons[id],
        metadata: { layer: LIDAR_LAYER_ID }
      })
    );
    dispatch(addActiveLayer(LIDAR_LAYER_ID));
    return;
  }

  dispatch({
    type: SET_LOCAL_LOADERS,
    payload: {
      names: [LOADERS.MAP],
      isLoading: true
    }
  });

  const url = `${getState().main.dynamicConfig.APIGateway}districts?type=lidar&id=${id}`;
  try {
    const response = await axios.get(url);

    const allItems = await processItems([...response.data])(dispatch);
    if (allItems?.length === 1) {
      dispatch({
        type: SET_LIDAR_POLYGONS,
        payload: allItems
      });

      dispatch(
        mapActions.setSelectedFeature({
          id: 1,
          geometry: allItems[0].LocationPolygon,
          properties: allItems[0],
          metadata: { layer: LIDAR_LAYER_ID }
        })
      );
      dispatch(addActiveLayer(LIDAR_LAYER_ID));

      dispatch({
        type: SET_LOCAL_LOADERS,
        payload: {
          names: [LOADERS.MAP],
          isLoading: false
        }
      });
    }
  } catch (e) {
    message.error(strings.infrastructure.loadDistrictsFailed);
    dispatch({
      type: SET_DISTRICTS,
      payload: []
    });
    dispatch(mapActions.setSelectedFeature({}));
    dispatch({
      type: SET_LOCAL_LOADERS,
      payload: {
        names: [LOADERS.MAP],
        isLoading: false
      }
    });
    throw e;
  }
};

export const loadDistricts = (isLoading = true) => async (dispatch, getState) => {
  dispatch({
    type: SET_LOCAL_LOADERS,
    payload: {
      names: [LOADERS.MAP],
      isLoading
    }
  });

  const url = `${getState().main.dynamicConfig.APIGateway}districts`;

  try {
    const response = await axios.get(url);

    const allItems = await processItems([...response.data])(dispatch);

    dispatch({
      type: SET_DISTRICTS,
      payload: allItems
    });

    dispatch({
      type: SET_LOCAL_LOADERS,
      payload: {
        names: [LOADERS.MAP],
        isLoading: false
      }
    });
  } catch (e) {
    message.error(strings.infrastructure.loadDistrictsFailed);
    dispatch({
      type: SET_DISTRICTS,
      payload: []
    });
    dispatch({
      type: SET_LOCAL_LOADERS,
      payload: {
        names: [LOADERS.MAP],
        isLoading: false
      }
    });
    throw e;
  }
};

export const loadAssets = () => async (dispatch, getState) => {
  if (
    currentUserHasPermission([
      POLES_DAMAGES_RW.key,
      POLES_DAMAGES_R.key,
      POLES_ASSETS_RW.key,
      POLES_ASSETS_R.key,
      CIP_R.key,
      CIP_RW.key
    ])
  ) {
    const urlAssets = `${getState().main.dynamicConfig.APIGateway}config/labels?type=asset`;
    const url = `${getState().main.dynamicConfig.APIGateway}config/labels?type=damage`;

    try {
      const response = await axios.get(url);
      const assetArray = response.data;

      const textToBool = text => text === 'Yes';
      const damageAttributeMapping = [
        {
          original: 'ClassId',
          new: 'classId'
        },
        {
          original: 'Label',
          new: 'label'
        },
        {
          original: 'Asset',
          new: 'asset'
        },
        {
          original: 'AssetVariation',
          new: 'variation'
        },
        {
          original: 'DamageCategory',
          new: 'category'
        },
        {
          original: 'DropDown',
          new: 'damage'
        },
        {
          original: 'IsDamage',
          new: 'isDamage',
          process: textToBool
        },
        {
          original: 'IsAsset',
          new: 'isAsset',
          process: textToBool
        },
        {
          original: 'isDisplayed',
          new: 'isDisplayed',
          process: textToBool
        }
      ];

      const assetsTree = {};
      const cipAssets = {};
      assetArray.forEach(damage => {
        const mappedDamage = damageAttributeMapping.reduce(
          (previousValue, attribute) => ({
            ...previousValue,
            [attribute.new]: attribute.process
              ? attribute.process(damage[attribute.original])
              : damage[attribute.original]
          }),
          {}
        );
        if (mappedDamage.isDisplayed && (mappedDamage.isDamage || mappedDamage.isAsset)) {
          let assetsObject;
          if (mappedDamage.isDamage) {
            assetsObject = assetsTree;
          } else {
            assetsObject = cipAssets;
          }
          let asset = assetsObject[mappedDamage.asset];

          if (asset) {
            let variation = asset[mappedDamage.variation];
            if (variation) {
              variation = {
                ...variation,
                [mappedDamage.damage]: `${
                  variation[mappedDamage.damage] ? `${variation[mappedDamage.damage]},` : ''
                }${mappedDamage.classId}`
              };
            } else {
              variation = {
                [mappedDamage.damage]: mappedDamage.classId
              };
            }
            asset = { ...asset, [mappedDamage.variation]: variation };
          } else {
            asset = {
              [mappedDamage.variation]: {
                [mappedDamage.damage]: mappedDamage.classId
              }
            };
          }
          assetsObject[mappedDamage.asset] = asset;
        }
      });

      const responseAssets = await axios.get(urlAssets);
      const assetReviewArray = responseAssets.data;

      const reviewAssets = assetReviewArray; // {};

      /* assetReviewArray.forEach(damage => {
        const mappedDamage = damageAttributeMapping.reduce((previousValue, attribute) => {
          return {
            ...previousValue,
            [attribute.new]: attribute.process
              ? attribute.process(damage[attribute.original])
              : damage[attribute.original]
          };
        }, {});
        if (mappedDamage.isDisplayed && mappedDamage.isAsset) {
          let asset = reviewAssets[mappedDamage.asset];
 
          if (asset) {
            let variation = asset[mappedDamage.variation];
            if (variation) {
              variation = {
                ...variation,
                [mappedDamage.damage]: `${
                  variation[mappedDamage.damage] ? `${variation[mappedDamage.damage]},` : ''
                }${mappedDamage.classId}`
              };
            } else {
              variation = {
                [mappedDamage.damage]: mappedDamage.classId
              };
            }
            asset = { ...asset, [mappedDamage.variation]: variation };
          } else {
            asset = {
              [mappedDamage.variation]: {
                [mappedDamage.damage]: mappedDamage.classId
              }
            };
          }
          reviewAssets[mappedDamage.asset] = asset;
        }
      }); */
      dispatch({
        type: LOAD_ASSETS,
        payload: { assetsTree, assetArray, cipAssets, reviewAssets }
      });
    } catch (e) {
      message.error(strings.infrastructure.loadAssetsFailed);
      dispatch({
        type: LOAD_ASSETS,
        payload: { assetsTree: {}, assetArray: [], cipAssets: {}, reviewAssets: {} }
      });
      throw e;
    }
  }
};

export const loadUserUploadDropdowns = () => async (dispatch, getState) => {
  const url = `${
    getState().main.dynamicConfig.APIGateway
  }config?dataIds=dataSource,dataOwner,imageSource,structureType`;

  try {
    const response = await axios.get(url);
    const userUploadData = response.data;

    dispatch({
      type: LOAD_USER_UPLOAD_DROPDOWNS,
      payload: { userUploadData }
    });
  } catch (e) {
    message.error(strings.infrastructure.loadAssetsFailed);
    dispatch({
      type: LOAD_USER_UPLOAD_DROPDOWNS,
      payload: { userUploadData: {} }
    });
    throw e;
  }
};

// TODO: update this method so it uses API with real data
export const loadProviders = () => async (dispatch /* , getState */) => {
  // const url = `${getState().main.dynamicConfig.APIGateway}config/providers`;
  try {
    // const response = await axios.get(url);
    // const providers = response.data;

    dispatch({
      type: LOAD_PROVIDERS,
      payload: providers
    });
  } catch (e) {
    message.error(strings.infrastructure.loadProvidersFailed);
    dispatch({
      type: LOAD_PROVIDERS,
      payload: []
    });
    throw e;
  }
};

export const setIdsToggle = isIdsToggle => dispatch => {
  dispatch({ type: SET_IDS_TOGGLE, payload: isIdsToggle });
};
// panel with filters on right bottom
export const setLidarDistrictHidden = isLidarDistrictHidden => dispatch => {
  dispatch({ type: SET_LIDAR_DISTRICTS_HIDDEN, payload: isLidarDistrictHidden });
};

export const setBucket = bucket => dispatch => {
  dispatch({ type: SET_BUCKET, payload: bucket });
};

const loadImageUrlMap = async (poleDetail, dispatch, getState) => {
  const { tab } = getState().view;
  const imageUrlMap = {};
  const resolutions = [1024, 120, 'original'];
  let url = '';

  if (tab === TABS.CIP) {
    url = `${getState().main.dynamicConfig.APIGateway}cip/pole/${
      poleDetail.FacilityId
    }/images?width=${resolutions.filter(res => typeof res === 'number').join(',')}`;
  } else {
    url = `${getState().main.dynamicConfig.APIGateway}poles/${
      poleDetail.Id
    }/images?width=${resolutions.filter(res => typeof res === 'number').join(',')}`;
  }

  const res = await axios.get(url);

  res.data.data.forEach(image =>
    resolutions.forEach(resolution => {
      imageUrlMap[image.path + resolution] = image[resolution];
    })
  );

  dispatch({ type: SET_IMAGEURL_MAP, payload: imageUrlMap });
};

export const loadStructure = (item, options) => async (dispatch, getState) => {
  const { tab } = getState().view;
  const { poleCoverage } = getState().infrastructure;

  if (!item.id) {
    return;
  }

  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.INFRA_SINGLE, LOADERS.MAP]);
  const cipTab = tab === TABS.CIP;
  let url = '';
  switch (tab) {
    case TABS.CIP:
      url = `${getState()?.main.dynamicConfig.APIGateway}cip/pole/${item.id}/details`;
      break;
    case TABS.QAQC:
      url = `${getState()?.main.dynamicConfig.APIGateway}qaqc/pole?FacilityId=${item.id}`;
      break;
    default:
      url = `${getState()?.main.dynamicConfig.APIGateway}pole?FacilityId=${item.id}`;
  }

  try {
    const response = await axios.get(url);
    const poleDetail = response.data;

    const DamageCount = poleDetail.Damages ? poleDetail.Damages.length : 0;

    const PictureCount =
      poleDetail.PictureBatches &&
      poleDetail.PictureBatches[0] &&
      poleDetail.PictureBatches[0].PictureRefs
        ? poleDetail.PictureBatches[0].PictureRefs.length.toString()
        : '0';

    poleDetail.PictureBatches &&
      (poleDetail.PictureBatches = poleDetail.PictureBatches.map(batch => ({
        ...batch,
        PictureRefs: batch.PictureRefs
          ? sortByStatus(
              batch.PictureRefs.map(img => ({
                ...img,
                status: getImageStatus(img, poleDetail.Damages)
              }))
            )
          : null
      })));

    const poleCoverageIds =
      poleCoverage.length !== 0 && poleCoverage[0].coverage.map(pole => pole.id);

    const Covered =
      poleCoverageIds && poleDetail.Id
        ? !poleCoverageIds.includes(poleDetail.Id.toString())
        : undefined;
    let payload = {};
    if (cipTab) {
      let providersByLevel = [];
      poleDetail.Providers &&
        poleDetail.Providers.forEach(prov =>
          prov.WireLevelsIIP.forEach(
            // eslint-disable-next-line
            level => {
              providersByLevel = [
                ...providersByLevel,
                {
                  ...prov,
                  Level: level.Level,
                  Assets: prov.Assets.filter(asset => asset.Level === level.Level)
                }
              ];
            }
          )
        );
      providersByLevel.sort((a, b) => a.Level.localeCompare(b.Level));

      dispatch({
        type: SET_PROVIDERS_BY_LEVEL,
        payload: providersByLevel
      });

      payload = {
        ...poleDetail,
        PictureCount,
        NoOfWireLevelsIIP: providersByLevel.length,
        NoOfWireLevelsSAP: poleDetail.Providers.length,
        Covered
      };
    } else {
      poleDetail.Damages = poleDetail.Damages?.map(damage => ({
        ...damage,
        Feedbacks: damage.Feedbacks?.sort((a, b) => moment(b.CreatedAt).diff(moment(a.CreatedAt))),
        imgIndex:
          poleDetail.PictureBatches &&
          [...poleDetail.PictureBatches[0].PictureRefs]
            .splice(0, poleDetail.Damages.length)
            .findIndex(
              picture =>
                picture.Bucket === damage.Image.Bucket && picture.Path === damage.Image.Path
            )
      }))
        .sort((a, b) => (a.imgIndex === b.imgIndex ? a.Id - b.Id : a.imgIndex - b.imgIndex))
        .map(damage => {
          const { imgIndex, ...rest } = damage;
          return rest;
        });

      const assetsGrouping = poleDetail.Assets
        ? poleDetail.Assets.map(asset => ({
            ...asset,
            Properties: asset.Properties
              ? {
                  AssetType:
                    asset?.Properties?.AssetType ||
                    asset?.Properties?.assetType ||
                    asset?.Properties['asset type'],
                  Material: asset?.Properties?.Material || asset?.Properties?.material,
                  Vendor: asset?.Properties?.Vendor || asset?.Properties?.vendor,
                  Configuration:
                    asset?.Properties?.Configuration || asset?.Properties?.configuration,
                  AssetVariation:
                    poleDetail.Damages && poleDetail.Damages.length
                      ? poleDetail.Damages[0].AssetVariation
                      : ''
                }
              : null
          }))
        : undefined;

      payload = {
        ...poleDetail,
        DamageCount,
        PictureCount,
        Covered,
        AssetsGroupings: assetsGrouping
      };
    }
    dispatch(setFilterVisibility(false));
    dispatch({
      type: SET_POLE_DETAIL,
      payload
    });
    dispatch(
      mapActions.setSelectedFeature({
        id: 1,
        geometry: {
          type: 'Point',
          coordinates: [payload.Geolocation[1], payload.Geolocation[0]]
        },
        properties: payload,
        metadata: { layer: mapTabToLayer(tab) }
      })
    );

    if (!options?.noImageReload) {
      await loadImageUrlMap(poleDetail, dispatch, getState);
    }

    if (options?.showSidebar) {
      dispatch(openSidebarDetail());
    }
    setLocalLoaders(false, [LOADERS.INFRA_SINGLE, LOADERS.MAP]);
  } catch (e) {
    message.error(strings.infrastructure.loadPoleItemFailed);
    dispatch({
      type: SET_POLE_DETAIL,
      payload: {}
    });
    dispatch(mapActions.clearSelectedFeature());
    const axiosError = new AxiosError(e);
    if (
      axiosError.request?.status === 403 &&
      !currentUserHasPermission([TRANSMISSION_ACCESS_R.key, TRANSMISSION_ACCESS_RW.key])
    ) {
      Modal.info(noTransmissionAccess);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.tooManyResults
      });
    }
    setLocalLoaders(false, [LOADERS.INFRA_SINGLE, LOADERS.MAP]);
    throw e;
  }
};

export const updateAssetReview = (feedbackRejected, comment, detail) => async (
  dispatch,
  getState
) => {
  const { username } = getState().main;
  const { selectedAssetGroupingIndex } = getState().infrastructure;
  const { Assets, FacilityId } = getState().infrastructure.poleDetail;
  const setLocalLoaders = createLocalLoaders(dispatch);

  const assetGroupId = Assets[selectedAssetGroupingIndex]?.AssetGroupId;

  const url = `${getState()?.main.dynamicConfig.APIGateway}assetgroups/${assetGroupId}/feedback`;

  const body = {
    feedback: feedbackRejected ? -1 : 1,
    reviewer: username,
    comment,
    detail
  };

  setLocalLoaders(true, [LOADERS.ASSET_REVIEW]);
  try {
    await axios.post(url, body);
  } catch (e) {
    message.error(strings.infrastructure.assetReviewFailed);
    throw e;
  } finally {
    await loadStructure({ id: FacilityId }, { noImageReload: false })(dispatch, getState);
    setLocalLoaders(false, [LOADERS.ASSET_REVIEW]);
  }
};

export const unsetPoleItem = tab => dispatch => {
  dispatch({ type: UNSET_POLE_DETAIL, payload: tab });
};

export const allowBoundingBoxEditAction = () => dispatch => {
  dispatch({
    type: SET_BOUNDING_BOX_EDIT_ON
  });
};

export const disableBoundingBoxEditAction = () => dispatch => {
  dispatch({
    type: SET_BOUNDING_BOX_EDIT_OFF
  });
};

export const addDamage = (id, payload) => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.ADD_DAMAGE]);
  const url = `${getState().main.dynamicConfig.APIGateway}damage`;

  try {
    const response = await axios.put(url, JSON.stringify(payload));
    const item = { id };
    await loadStructure(item, { noImageReload: true })(dispatch, getState);

    // add new damage into selected pole
    dispatch({
      type: ADD_NEW_DAMAGE,
      payload: {
        damage: response.data
      }
    });

    setLocalLoaders(false, [LOADERS.ADD_DAMAGE]);
  } catch (e) {
    message.error(strings.infrastructure.addDamageFailed);
    dispatch({
      type: ADD_NEW_DAMAGE,
      payload: {}
    });
    throw e;
  }
};

export const setActiveDamage = isActiveDamage => dispatch => {
  dispatch({ type: SET_ACTIVE_DAMAGE, payload: isActiveDamage });
};

export const resetActiveDamage = () => dispatch => {
  dispatch({ type: RESET_ACTIVE_DAMAGE });
};

export const setCurrentImage = currentImage => dispatch => {
  dispatch({ type: SET_CURRENT_IMAGE, payload: currentImage });
};

const getUnreviewedDamageOnPole = item => item.Damages?.find(damage => damage.Status === 'SCANNED');

const getUnreviewedPole = poles => poles.find(pole => pole.Status === 1); // status 1 is Predicted Damaged

const getUnReviewedDamageOnImage = (poleItem, getState) => {
  const currentImageIndex = getState().infrastructure.currentImage;

  return poleItem.Damages && poleItem.PictureBatches[0].PictureRefs[currentImageIndex]
    ? poleItem.Damages.find(
        damage =>
          damage.Image.Path === poleItem.PictureBatches[0].PictureRefs[currentImageIndex].Path &&
          damage.Image.Bucket ===
            poleItem.PictureBatches[0].PictureRefs[currentImageIndex].Bucket &&
          damage.Status === 'SCANNED'
      )
    : null;
};
/**
 * @return IMAGE_STATUS
 *
 * @param image - image object which contains Path and Bucket props
 * @param allDamagesArr - damages array from pole detail object
 */
const getImageStatus = (image, allDamagesArr) => {
  if (!image || !image.Bucket || !image.Path) {
    return IMAGE_STATUS.ERROR;
  }
  const matchedImage = _.find(
    allDamagesArr,
    damage =>
      damage &&
      damage.Image &&
      damage.Image.Path === image.Path &&
      damage.Image.Bucket === image.Bucket
  );
  if (!matchedImage) {
    return IMAGE_STATUS.NO_DAMAGE;
  }
  if (matchedImage.Status === 'REVIEWED') {
    return IMAGE_STATUS.REVIEWED;
  }
  if (matchedImage.Status === 'SCANNED') {
    return IMAGE_STATUS.TO_REVIEW;
  }
  return IMAGE_STATUS.ERROR; // fallback
};

// Sorting array by the status field
const sortByStatus = array =>
  array.sort((a, b) => {
    const searchPattern = status => status === 'TO_REVIEW' || status === 'REVIEWED';
    return searchPattern(b.status) - searchPattern(a.status);
  });

export const searchNextDamage = () => async (dispatch, getState) => {
  const poleItem = getState().infrastructure.poleDetail;

  let newDamage;
  newDamage = getUnReviewedDamageOnImage(poleItem, getState);

  const hasUnreviewedDamage = poleItem.Damages?.some(damage => damage.Feedbacks.length === 0);
  if (hasUnreviewedDamage && (!newDamage || newDamage === undefined)) {
    newDamage = getUnreviewedDamageOnPole(poleItem);
    const newCurrentImage = poleItem.PictureBatches[0].PictureRefs.findIndex(
      index => index.Path === newDamage?.Image?.Path && index.Bucket === newDamage?.Image?.Bucket
    );
    dispatch({ type: SET_ACTIVE_DAMAGE, payload: newDamage });
    dispatch({ type: SET_CURRENT_IMAGE, payload: newCurrentImage });
  } else if (!hasUnreviewedDamage) {
    const { displayedItems } = getState().infrastructure;
    const poles = Object.values(getState().infrastructure.byId).filter(
      item => displayedItems.findIndex(dispItem => dispItem === item.arangoUuid) > -1
    );

    const item = getUnreviewedPole(poles);

    if (item) {
      // TODO: this process currently does not change the facilityId in url
      await loadStructure({ id: item.FacilityId })(dispatch, getState);
      await setIsAutomaticSwitchFlag(true)(dispatch);
      await setSelectedItem(item)(dispatch, getState);
      const newPoleItem = getState().infrastructure.poleDetail;

      const unreviewedDamage = getUnreviewedDamageOnPole(newPoleItem);

      const newImageIndex =
        unreviewedDamage &&
        newPoleItem.PictureBatches[0].PictureRefs.findIndex(
          index =>
            index.Path === unreviewedDamage.Image.Path &&
            index.Bucket === unreviewedDamage.Image.Bucket
        );

      if (newImageIndex > -1) {
        dispatch({ type: SET_ACTIVE_DAMAGE, payload: unreviewedDamage });
        dispatch({ type: SET_CURRENT_IMAGE, payload: newImageIndex });
      } else {
        dispatch({ type: HIDE_BIG_IMAGE });
        dispatch({ type: SET_ACTIVE_DAMAGE, payload: undefined });
        dispatch({ type: SET_CURRENT_IMAGE, payload: null });
      }
    } else {
      const originalDamage = getState().infrastructure.activeDamage;
      const updatedDamage = poleItem.Damages.find(damage => damage.Id === originalDamage.Id);
      dispatch({ type: SET_ACTIVE_DAMAGE, payload: updatedDamage });
    }
  } else {
    dispatch({ type: SET_ACTIVE_DAMAGE, payload: newDamage });
  }
};

/**
 * send feedback for damage or qaqc discrepancy
 * @param feedbacks - object of following properties:
 * {
 *   Id: number;
 *   Comment: string;
 *   FeedbackItems: {
 *    Type: string;
 *    Feedback: number;
 *    Value: string | string[];
 *   }[]
 * }
 */
export const sendFeedback = (feedbacks, damageId, item) => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  if (!validateItem(feedbacks)) {
    return;
  }
  setLocalLoaders(true, [LOADERS.SEND_FEEDBACK]);
  const { username } = getState().main;
  const { tab } = getState().view;
  const postBody = {
    Reviewer: username,
    IsDamaged: feedbacks.IsDamaged,
    ClassId: feedbacks.ClassId,
    Feedback: feedbacks.Feedback,
    Comment: feedbacks.Comment,
    Severity: feedbacks.Severity
  };

  if (tab === TABS.QAQC) {
    postBody.Label = damageId;
    postBody.DiarId = item.DiarId;
  }

  try {
    const body = JSON.stringify(postBody);
    const urlParams = tab === TABS.QAQC ? `?&poleId=${item.Id}` : `?&damageId=${damageId}`;
    const url = `${getState().main.dynamicConfig.APIGateway}${
      tab === TABS.QAQC ? 'qaqc/' : ''
    }feedback${urlParams}`;

    const result = await axios.post(url, body, {
      headers: { 'Content-Type': 'application/json' }
    });
    if (!result.statusText === 'OK') {
      const error = result.status !== 400 ? (await result.json()) || {} : {};
      message.error(error.Message || strings.infrastructure.editItemFailed);
    } else {
      message.success(strings.infrastructure.successEditItem);
      if (tab !== TABS.QAQC) {
        // update damage in redux, if the update is successfully stored in API
        const updatedPoleDetail = updateDamageStatus(item, damageId, postBody);
        dispatch({
          type: SET_POLE_DETAIL,
          payload: updatedPoleDetail
        });
        await searchNextDamage()(dispatch, getState);
      }
      if (tab === TABS.QAQC) {
        // update discrepancy, if the update is successfully stored in API
        const updatedPoleDetail = updateDiscrepancy(item, damageId, postBody);
        dispatch({
          type: SET_POLE_DETAIL,
          payload: updatedPoleDetail
        });
      }
    }
    setLocalLoaders(false, [LOADERS.SEND_FEEDBACK]);
  } catch (e) {
    message.error(strings.infrastructure.sendFeedbackFailed);
    setLocalLoaders(false, [LOADERS.SEND_FEEDBACK]);
    throw e;
  }
};

export const setSelectedItem = id => (dispatch, getState) => {
  const { secondaryNewItem } = getState().infrastructure;
  if (secondaryNewItem) {
    dispatch({
      type: SET_SECONDARY_CREATE_FLAG,
      payload: false
    });
  }
  dispatch({ type: SET_LIDAR_FILES_FOR_SELECTED_POLE, payload: [] });
  dispatch({
    type: SET_SELECTED_ITEM,
    payload: { id, tab: getState().infrastructure.tab }
  });
  if (id && Object.keys(id).length > 0) {
    dispatch(openSidebarDetail(MODES.DETAIL));
  }
};

export const setHoveredLidar = id => ({
  type: SET_HOVERED_LIDAR,
  payload: id
});

export const setNewItemValues = values => ({
  type: SET_NEW_ITEM_FORM_VALUES,
  payload: values
});

export const resetNewItemValues = (form = {}) => ({
  type: RESET_NEW_ITEM_FORM,
  payload: form
});

export const validateItem = formData => {
  // validation

  if (formData.MessageType === messages.asset) {
    let metadata;
    try {
      metadata = JSON.parse(formData.Metadata);
    } catch (e) {
      message.error(strings.infrastructure.invalidJSON);
      return false;
    }

    if (Object.values(metadata).some(value => value instanceof Object || value instanceof Array)) {
      message.error(strings.infrastructure.flatJSONError);
      return false;
    }
  }

  return true;
};

export const getParentItem = item => async dispatch => {
  const result = await fetch(`/GIS/items/${item.id}/parents`, {
    credentials: 'include'
  });

  if (result.ok) {
    const parent = await result.json();
    dispatch({
      type: SET_NEW_ITEM_FORM_VALUES,
      payload: {
        ParentObject: {
          ...parent[0],
          id: arangoUuid(parent[0])
        }
      }
    });
  }
};

export const setChildItemFlag = flag => ({
  type: SET_CHILD_ITEM_FLAG,
  payload: flag
});

export const setSidebarSpecialCss = value => dispatch => {
  dispatch({ type: SET_SIDEBAR_SPECIAL_CSS, payload: value });
};

export const showBigImage = () => dispatch => {
  dispatch({ type: SHOW_BIG_IMAGE });
};

export const hideBigImage = () => dispatch => {
  dispatch({ type: HIDE_BIG_IMAGE });
};

export const showSmallImage = () => dispatch => {
  dispatch({ type: SHOW_SMALL_IMAGE });
};

export const hideSmallImage = () => dispatch => {
  dispatch({ type: HIDE_SMALL_IMAGE });
};

export const setSmallImageIndex = index => dispatch => {
  dispatch({ type: SET_SMALL_IMAGE_INDEX, payload: index });
};

export const setFilterVisibility = isVisible => dispatch => {
  dispatch({ type: SET_FILTER_VISIBILITY, payload: isVisible });
};
export const setBoundingBoxVisibility = isVisible => dispatch => {
  dispatch({ type: SET_BOUNDING_BOX_VISIBILITY, payload: isVisible });
};

export const resetFocusOnItems = () => dispatch => {
  dispatch({ type: RESET_FOCUS_ON_ITEMS });
};

export const setIsAutomaticSwitchFlag = isAutomaticSwitch => dispatch => {
  dispatch({ type: SET_IS_AUTOMATIC_SWITCH_FLAG, payload: isAutomaticSwitch });
};

export const negativeFeedbackPanel = negativeFeedback => dispatch => {
  dispatch({ type: NEGATIVE_FEEDBACK_PANEL, payload: negativeFeedback });
};

export const toggleImageToolsPanel = imageTools => dispatch => {
  dispatch({ type: TOGGLE_IMAGE_TOOLS_PANEL, payload: imageTools });
};

export const toggleLabelingToolsPanel = labelingTools => dispatch => {
  dispatch({ type: TOGGLE_LABELING_TOOLS_PANEL, payload: labelingTools });
};

export const updateImageBrightness = value => dispatch => {
  dispatch({ type: UPDATE_IMAGE_BRIGHTNESS, payload: value });
};

export const updateImageContrast = value => dispatch => {
  dispatch({ type: UPDATE_IMAGE_CONTRAST, payload: value });
};

export const resetImageFilters = () => dispatch => {
  dispatch({ type: RESET_IMAGE_FILTERS });
};

export const exportQaqc = (startDate, endDate, reportType) => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.QAQC_EXPORT]);
  const url = `${
    getState().main.dynamicConfig.APIGateway
  }qaqc/report?startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`;

  try {
    const response = await axios.get(url, { responseType: 'blob' });

    dispatch({ type: SET_QAQC_EXPORT_FILE, payload: response.data });
  } catch (e) {
    message.error(strings.infrastructure.loadQaqcExportFailed);
    setLocalLoaders(false, [LOADERS.QAQC_EXPORT]);
    throw e;
  }
};

export const qaqcExportFinished = () => dispatch => {
  dispatch({ type: SET_QAQC_EXPORT_FINISHED });
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(false, [LOADERS.QAQC_EXPORT]);
};

export const confirmPole = item => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.VERIFY_CIP]);

  const url = `${getState().main.dynamicConfig.APIGateway}/cip/pole/${
    item.FacilityId
  }/levels/status`;

  try {
    const response = await axios.put(url);
    response && (await loadStructure({ id: item.FacilityId })(dispatch, getState));
    setLocalLoaders(false, [LOADERS.VERIFY_CIP]);
  } catch (e) {
    message.error(strings.infrastructure.confirmPoleFailed);
    setLocalLoaders(false, [LOADERS.VERIFY_CIP]);
    throw e;
  }
};

export const changeHoldStatus = item => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.CIP_HOLD]);

  const url = `${getState().main.dynamicConfig.APIGateway}/${
    item.FacilityId
  }/hold?holdStatus=${!item.OnHold}`;

  try {
    const response = await axios.put(url);
    response && (await loadStructure({ id: item.FacilityId })(dispatch, getState));
    setLocalLoaders(false, [LOADERS.CIP_HOLD]);
  } catch (e) {
    message.error(strings.infrastructure.confirmPoleFailed);
    setLocalLoaders(false, [LOADERS.CIP_HOLD]);
    throw e;
  }
};

export const markPhotoEvidenceForDeletion = assetId => dispatch => {
  dispatch({ payload: assetId, type: SET_PHOTO_EVIDENCE_FOR_DELETION });
};

export const resetPhotoEvidenceForDeletion = () => dispatch => {
  dispatch({ type: RESET_PHOTO_EVIDENCE_FOR_DELETION });
};

export const setCipDeletedAnnotation = annotation => dispatch => {
  dispatch({ type: SET_CIP_DELETED_ANNOTATION, payload: annotation });
};

export const toggleSmallMapCollapse = collapse => dispatch => {
  dispatch({ type: SET_SMALL_MAP_COLLAPSE, payload: collapse });
};

export const deleteProvider = lastAsset => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.CIP_PANEL]);

  const poleItem = getState().infrastructure.poleDetail;
  const assetId = getState().infrastructure.cipDeletedAnnotation;
  const url = `${getState().main.dynamicConfig.APIGateway}cip/asset/${assetId}/reject`;
  try {
    await axios.put(url);
    lastAsset && hideBigImage()(dispatch);
    await loadStructure({ id: poleItem.FacilityId }, { noImageReload: true })(dispatch, getState);
    await setCipDeletedAnnotation(null)(dispatch, getState);
    setLocalLoaders(false, [LOADERS.CIP_PANEL]);
  } catch (e) {
    message.error(strings.infrastructure.deleteAssetFailed);
    setLocalLoaders(false, [LOADERS.CIP_PANEL]);
    throw e;
  }
};

export const sendEditedAttachments = (item, updatedProviders) => async (dispatch, getState) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.SAVE_ATTACHMENTS]);

  const url = `${getState().main.dynamicConfig.APIGateway}/cip/pole/${item.FacilityId}/levels`;

  try {
    await axios.put(url, updatedProviders);
    await loadStructure({ id: item.FacilityId })(dispatch, getState);
    setLocalLoaders(false, [LOADERS.SAVE_ATTACHMENTS]);
  } catch (e) {
    message.error(strings.infrastructure.editAttachmentsFailed);
    setLocalLoaders(false, [LOADERS.SAVE_ATTACHMENTS]);
    throw e;
  }
};

export const addProvider = (provider, equipment, level, imageId, boundingBox) => async (
  dispatch,
  getState
) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.CIP_PANEL]);

  const poleItem = getState().infrastructure.poleDetail;
  const detectedAt = new Date().toJSON();

  const putBody = {
    ImageId: parseInt(imageId),
    BoundingBox: boundingBox,
    ClassId: parseInt(equipment),
    DetectedAt: detectedAt,
    Level: level,
    ProviderCustomerNumber: provider
  };

  const url = `${getState().main.dynamicConfig.APIGateway}cip/pole/${poleItem.FacilityId}/asset`;
  try {
    const body = JSON.stringify(putBody);
    const response = await axios.put(url, body);

    response && (await loadStructure({ id: poleItem.FacilityId }, true)(dispatch, getState));
    setLocalLoaders(false, [LOADERS.CIP_PANEL]);
  } catch (e) {
    message.error(strings.infrastructure.addAssetFailed);
    setLocalLoaders(false, [LOADERS.CIP_PANEL]);
    throw e;
  }
};

export const setAddNewProvider = add => dispatch => {
  dispatch({ type: SET_ADD_NEW_PROVIDER, payload: add });
};

export const calculateClassId = (filters, assetTree) => {
  if (filters.damages.length > 0) {
    return filters.damages.join(',');
  }
  return Object.entries(assetTree)
    .reduce((classIds, [assetKey, assetValue]) => {
      if (filters.assets.includes(assetKey)) {
        const variationIds = Object.values(assetValue).reduce(
          (classIdsVariation, variationValue) => [
            ...classIdsVariation,
            ...Object.values(variationValue)
          ],
          []
        );
        return [...classIds, ...variationIds];
      }
      return classIds;
    }, [])
    .join(',');
};

export const setSelectedImage = image => ({ type: SET_SELECTED_IMAGE, payload: image });

export const setDownloadModalOpen = isOpen => ({ type: SET_DOWNLOAD_MODAL_OPEN, payload: isOpen });

export const setProgressOpen = isOpen => ({ type: SET_DOWNLOAD_PROGRESS_OPEN, payload: isOpen });

export const setSelectedAssetGroupingIndex = index => ({
  type: SET_SELECTED_ASSET_GROUPING_INDEX,
  payload: index
});

export const setImagesForDownload = fileList => ({
  type: SET_IMAGES_FOR_DOWNLOAD,
  payload: fileList
});

export const changePhotosForFollowUp = (photoIdsList, flag, resetEditing) => async (
  dispatch,
  getState
) => {
  const setLocalLoaders = createLocalLoaders(dispatch);
  setLocalLoaders(true, [LOADERS.FLAG_PHOTOS]);
  const poleItem = getState().infrastructure.poleDetail;
  const url = `${getState().main.dynamicConfig.APIGateway}edit-images?followUp=${flag}`;
  const putBody = {
    pictureIds: photoIdsList
  };

  try {
    const response = await axios.put(url, JSON.stringify(putBody));
    response && (await loadStructure({ id: poleItem.FacilityId }, true)(dispatch, getState));
    setLocalLoaders(false, [LOADERS.FLAG_PHOTOS]);
    resetEditing();
  } catch (e) {
    setLocalLoaders(false, [LOADERS.FLAG_PHOTOS]);
    message.error(strings.infrastructure.flagFailed);
    resetEditing();
    throw e;
  }
};

export const downloadImages = (fileList, size) => async (dispatch, getState) => {
  const baseUrl = getState().main.dynamicConfig.APIGateway;
  const poleId = getState().infrastructure.poleDetail.FacilityId;
  const imageCountsByPoleId = getState().infrastructure.imageCountDownloading;

  const removeImageCount = () => {
    const newImageCounts = _.omit(getState().infrastructure.imageCountDownloading, poleId);
    dispatch({ type: SET_IMAGE_COUNT_DOWNLOADING, payload: newImageCounts });
    if (Object.keys(newImageCounts).length === 0) {
      setProgressOpen(false);
    }
  };

  // disable additional download for same poleId
  if (imageCountsByPoleId && Object.prototype.hasOwnProperty.call(imageCountsByPoleId, poleId)) {
    message.error('Wait for images in current pole to be downloaded.');
  } else {
    dispatch({
      type: SET_IMAGE_COUNT_DOWNLOADING,
      payload: { ...imageCountsByPoleId, [poleId]: fileList.length }
    });

    const idList = fileList.map(file => file.Id.toString());

    try {
      const url = `${baseUrl}poles/${poleId}/images/download`;
      const body = { pictureIds: idList, resolution: size === imageSize.RESIZED ? '1024' : null };
      const response = await axios.post(url, JSON.stringify(body));

      const repeatRequest = async repetition => {
        if (repetition > 0) {
          const url2 = `${baseUrl}/file/download/presigned-url?bucket=${response.data.Bucket}&owner=asd-download&key=${response.data.Key}`;
          await axios
            .get(url2)
            .catch(e => {
              if (e.response.status === 404) {
                setTimeout(() => {
                  repeatRequest(repetition - 1);
                }, 5000);
              } else if (e.response.status !== 200) {
                message.error(strings.infrastructure.imageDownloadFailed);
                removeImageCount();
              }
            })
            .then(async value => {
              if (value && value.data) {
                const link = document.createElement('a');
                link.href = value.data;
                link.setAttribute('download', 'images.zip');
                document.body.append(DOMPurify.sanitize(link));
                link.click();
                link.remove();

                if (getState().infrastructure.isDownloadModalOpen)
                  dispatch({ type: SET_IMAGE_DOWNLOADING_DONE, payload: true });

                removeImageCount();
              }
            });
        }
      };

      // check zip processing for 15 minutes
      await repeatRequest(180);
    } catch {
      message.error(strings.infrastructure.imageDownloadFailed);
      removeImageCount();
    }
  }
};

export const resetDownloadModal = () => dispatch => {
  dispatch({ type: SET_IMAGE_DOWNLOADING_DONE, payload: false });
  dispatch({ type: SET_IMAGES_FOR_DOWNLOAD, payload: [] });
};

export const resetPoleCoverage = () => dispatch => {
  dispatch({ type: SET_POLE_COVERAGE, payload: [] });
};

export const getPoleCoverage = () => async (dispatch, getState) => {
  const { filters } = getState().infrastructure;
  const poles = Object.values(getState().infrastructure.byId);
  dispatch({
    type: SET_LOCAL_LOADERS,
    payload: {
      names: [LOADERS.COVERAGE],
      isLoading: true
    }
  });
  let filterURL = '';
  if (filters.imageSource !== '') {
    filterURL = `${filterURL}&source=${filters.imageSource}`;
  }
  if (filters.dateFrom) {
    filterURL = `${filterURL}&startDate=${moment.utc(filters.dateFrom).format()}`;
  }
  if (filters.dateTo) {
    filterURL = `${filterURL}&endDate=${moment.utc(filters.dateTo).format()}`;
  }

  const url = `${getState()?.main.dynamicConfig.APIGateway}pole-coverage?district=${
    filters.district
  }${filterURL}`;

  try {
    const response = await axios.get(url);

    const result = Array.isArray(response.data) ? response.data : [response.data];
    if (result.length > 1500) {
      Modal.info(tooManyPolesModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.tooManyResults
      });
    } else if (result.length === 0) {
      Modal.info(noResultsModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.noContent
      });
    } else {
      const items = result.map(item => ({
        ...item,
        rangeKey: item.rangeKey ? item.rangeKey : `_${Math.random().toString(36).substr(2, 9)}`
      }));

      if (items.length !== 0 && poles.length !== 0) {
        const poleCoverageIds = items[0].coverage.map(pole => pole.id);
        const allItems = poles.map(item =>
          poleCoverageIds.includes(item.Id.toString()) || item.District !== filters.district
            ? { ...item, Covered: false }
            : { ...item, Covered: true }
        );

        dispatch({
          type: SET_INFRASTRUCTURE_ITEMS,
          payload: allItems
        });
      }

      dispatch({
        type: SET_POLE_COVERAGE,
        payload: items
      });

      dispatch({
        type: SET_LOCAL_LOADERS,
        payload: {
          names: [LOADERS.COVERAGE],
          isLoading: false
        }
      });
    }
  } catch (e) {
    const axiosError = new AxiosError(e);
    dispatch({
      type: SET_POLE_COVERAGE,
      payload: {}
    });
    dispatch({
      type: SET_LOCAL_LOADERS,
      payload: {
        names: [LOADERS.COVERAGE],
        isLoading: false
      }
    });
    if (axiosError.request?.status === 413) {
      Modal.info(tooManyPolesModal);
      dispatch({
        type: SET_WARNING_MESSAGE,
        payload: strings.infrastructure.tooManyResults
      });
    } else {
      if (axiosError.response?.status !== 404) {
        message.error(strings.infrastructure.loadPoleCoverageFailed);
      }
      throw e;
    }
  }
};

export const setCheckedBB = checked => dispatch => {
  dispatch({ type: SET_CHECKED_BB, payload: checked });
};

export const setCheckedAll = checked => dispatch => {
  dispatch({ type: SET_CHECKED_ALL, payload: checked });
};
