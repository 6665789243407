// settings
export const SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS';
export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOAD_PROVIDERS = 'LOAD_PROVIDERS';
export const LOAD_USER_UPLOAD_DROPDOWNS = 'LOAD_USER_UPLOAD_DROPDOWNS';

// main
export const DYNAMIC_CONFIG = 'DYNAMIC_CONFIG';

export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_CUSTOM_STATE = 'SET_CUSTOM_STATE';

// loaders
export const SET_LOADER = 'SET_LOADER';
export const SET_LOCAL_LOADER = 'SET_LOCAL_LOADER';
export const SET_LOCAL_LOADERS = 'SET_LOCAL_LOADERS';

// infrastructure
export const SET_INFRASTRUCTURE_ITEMS = 'SET_INFRASTRUCTURE_ITEMS';
export const SET_POLE_DETAIL = 'SET_POLE_DETAIL';
export const SET_UPSTREAMDOWNSTREAM_POLYGONS = 'SET_UPSTREAMDOWNSTREAM_POLYGONS';
export const SET_IDS_TOGGLE = 'SET_IDS_TOGGLE';
export const SET_INDOOR = 'SET_INDOOR';
export const SET_VIEWPORT = 'SET_VIEWPORT';
export const SET_INFRASTRUCTURE_TAB = 'SET_INFRASTRUCTURE_TAB';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_HOVERED_LIDAR = 'SET_HOVERED_LIDAR';
export const SET_LIDAR_FILES_FOR_SELECTED_POLE = 'SET_LIDAR_FILES_FOR_SELECTED_POLE';
export const SET_NEW_ITEM_FORM_VALUES = 'SET_NEW_ITEM_FORM_VALUES';
export const RESET_NEW_ITEM_FORM = 'RESET_NEW_ITEM_FORM';
export const SET_INFRASTRUCTURE_MAP_CONTROLS = 'SET_INFRASTRUCTURE_MAP_CONTROLS';
export const SET_SECONDARY_CREATE_FLAG = 'SET_SECONDARY_CREATE_FLAG';
export const SET_INSTRUCTION_OPTIONS = 'SET_INSTRUCTION_OPTIONS';
export const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS';
export const SET_GLOBAL_EDIT_FLAG = 'SET_GLOBAL_EDIT_FLAG';
export const SET_BOUNDARY_SELECTED = 'SET_BOUNDARY_SELECTED';
export const SET_CHILD_ITEM_FLAG = 'SET_CHILD_ITEM_FLAG';
export const SET_IMPORTED_ITEMS = 'SET_IMPORTED_ITEMS';
export const SET_SELECTED_BUILDING_PART = 'SET_SELECTED_BUILDING_PART';
export const SET_FOCUS_ON_ITEMS = 'SET_FOCUS_ON_ITEMS';
export const SET_ROADS = 'SET_ROADS';
export const SET_INFRASTRUCTURE_FILTERS = 'SET_INFRASTRUCTURE_FILTERS';
export const UPDATE_SELECTED_DAMAGE = 'UPDATE_SELECTED_DAMAGE';
export const ADD_NEW_DAMAGE = 'ADD_NEW_DAMAGE';
export const SET_BOUNDING_BOX_EDIT_ON = 'SET_BOUNDING_BOX_EDIT_ON';
export const SET_BOUNDING_BOX_EDIT_OFF = 'SET_BOUNDING_BOX_EDIT_OFF';
export const SET_INFRASTRUCTURE_ITEM = 'SET_INFRASTRUCTURE_ITEM';
export const SET_BUCKET = 'SET_BUCKET';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_LIDAR_POLYGONS = 'SET_LIDAR_POLYGONS';
export const SET_FIRE_DISTRICTS = 'SET_FIRE_DISTRICTS';
export const SET_LIDAR_DISTRICTS_HIDDEN = 'SET_LIDAR_DISTRICTS_HIDDEN';
export const SET_OPERATIONAL_DISTRICTS = 'SET_OPERATIONAL_DISTRICTS';
export const SET_SATELLITE_DISTRICTS = 'SET_SATELLITE_DISTRICTS';
export const SET_COVERAGE_DISTRICTS = 'SET_COVERAGE_DISTRICTS';
export const SHOW_BIG_IMAGE = 'SHOW_BIG_IMAGE';
export const HIDE_BIG_IMAGE = 'HIDE_BIG_IMAGE';
export const UNSET_POLE_DETAIL = 'UNSET_POLE_DETAIL';
export const SHOW_SMALL_IMAGE = 'SHOW_SMALL_IMAGE';
export const HIDE_SMALL_IMAGE = 'HIDE_SMALL_IMAGE';
export const SET_SMALL_IMAGE_INDEX = 'SET_SMALL_IMAGE_INDEX';
export const SET_FILTER_VISIBILITY = 'SET_FILTER_VISIBILITY';
export const SET_BOUNDING_BOX_VISIBILITY = 'SET_BOUNDING_BOX_VISIBILITY';
export const SET_IMAGEURL_MAP = 'SET_IMAGEURL_MAP';
export const SET_ACTIVE_DAMAGE = 'SET_ACTIVE_DAMAGE';
export const SET_CURRENT_IMAGE = 'SET_CURRENT_IMAGE';
export const RESET_INFRASTRUCTURE_FILTERS = 'RESET_INFRASTRUCTURE_FILTERS';
export const RESET_ACTIVE_DAMAGE = 'RESET_ACTIVE_DAMAGE';
export const RESET_FOCUS_ON_ITEMS = 'RESET_FOCUS_ON_ITEMS';
export const SET_IS_AUTOMATIC_SWITCH_FLAG = 'SET_IS_AUTOMATIC_SWITCH_FLAG';
export const NEGATIVE_FEEDBACK_PANEL = 'NEGATIVE_FEEDBACK_PANEL';
export const SET_QAQC_EXPORT_FILE = 'SET_QAQC_EXPORT_FILE';
export const SET_PROVIDERS_BY_LEVEL = 'SET_PROVIDERS_BY_LEVEL';
export const SET_QAQC_EXPORT_FINISHED = 'SET_QAQC_EXPORT_FINISHED';
export const SET_PHOTO_EVIDENCE_FOR_DELETION = 'SET_PHOTO_EVIDENCE_FOR_DELETION';
export const RESET_PHOTO_EVIDENCE_FOR_DELETION = 'RESET_PHOTO_EVIDENCE_FOR_DELETION';
export const SET_CIP_DELETED_ANNOTATION = 'SET_CIP_DELETED_ANNOTATION';
export const SET_SIDEBAR_SPECIAL_CSS = 'SET_SIDEBAR_SPECIAL_CSS';
export const SET_SMALL_MAP_COLLAPSE = 'SET_SMALL_MAP_COLLAPSE';
export const SET_ADD_NEW_PROVIDER = 'SET_ADD_NEW_PROVIDER';
export const SET_SELECTED_IMAGE = 'SET_SELECTED_IMAGE';
export const SET_DOWNLOAD_MODAL_OPEN = 'SET_DOWNLOAD_MODAL_OPEN';
export const SET_DOWNLOAD_PROGRESS_OPEN = 'SET_DOWNLOAD_PROGRESS_OPEN';
export const SET_IMAGES_FOR_DOWNLOAD = 'SET_IMAGES_FOR_DOWNLOAD';
export const SET_IMAGE_COUNT_DOWNLOADING = 'SET_IMAGE_COUNT_DOWNLOADING';
export const SET_IMAGE_DOWNLOADING_DONE = 'SET_IMAGE_DOWNLOADING_DONE';
export const TOGGLE_IMAGE_TOOLS_PANEL = 'TOGGLE_IMAGE_TOOLS_PANEL';
export const TOGGLE_LABELING_TOOLS_PANEL = 'TOGGLE_LABELING_TOOLS_PANEL';
export const SET_POLE_COVERAGE = 'SET_POLE_COVERAGE';
export const UPDATE_IMAGE_BRIGHTNESS = 'UPDATE_IMAGE_BRIGHTNESS';
export const UPDATE_IMAGE_CONTRAST = 'UPDATE_IMAGE_CONTRAST';
export const RESET_IMAGE_FILTERS = 'RESET_IMAGE_FILTERS';
export const SET_ZOOMED_POLE = 'SET_ZOOMED_POLE';
export const SET_SELECTED_ASSET_GROUPING_INDEX = 'SET_SELECTED_ASSET_GROUPING_INDEX';
export const SET_CHECKED_BB = 'SET_CHECKED_BB';
export const SET_CHECKED_ALL = 'SET_CHECKED_ALL';
export const SET_WARNING_MESSAGE = 'SET_WARNING_MESSAGE';

// sidebar
export const SET_SIDEBAR_MODE = 'SET_SIDEBAR_MODE';
export const SET_SIDEBAR_COLLAPSED = 'SET_SIDEBAR_COLLAPSED';
export const SET_SIDEBAR_SCROLL_POSITION = 'SET_SIDEBAR_SCROLL_POSITION';

// responsive
export const SET_RESPONSIVE = 'SET_RESPONSIVE';
