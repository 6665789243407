import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setResponsive } from '../containers/utils/actions';
import { SIZES } from '../containers/utils/constants';

class ResponsiveCalc extends React.Component {
  componentDidMount() {
    this.processResize(window);

    window.addEventListener('resize', () => {
      this.processResize(window);
    });
  }

  processResize = ({ innerWidth: width, innerHeight: height, matchMedia }) => {
    document.documentElement.style.setProperty('--fullHeight', `${height}px`);

    let size;
    if (width < 768) {
      // $breakpointSm
      size = SIZES.XS;
    } else if (width < 979) {
      // $breakpointMd
      size = SIZES.SM;
    } else if (width < 1200) {
      // $breakpointLg
      if (matchMedia('(orientation: portrait)').matches) {
        size = SIZES.MD;
      } else {
        size = SIZES.MD_LANDSCAPE;
      }
    } else {
      size = SIZES.LG;
    }
    if (this.props.responsive.size !== size) {
      this.props.setResponsive(size);
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  responsive: state.responsive
});

const mapDispatchToProps = dispatch => bindActionCreators({ setResponsive }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveCalc);
