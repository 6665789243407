export const SET_UAS_UPLOADING = 'SET_UAS_UPLOADING';
export const SET_UAS_ERROR = 'SET_UAS_ERROR';
export const SET_UAS_DONE_UPLOADING = 'SET_UAS_DONE_UPLOADING';
export const RESET_UAS_DONE_UPLOADING = 'RESET_UAS_DONE_UPLOADING';
export const DONE_UPLOADING = 'DONE_UPLOADING';
export const RESET_UAS_STATE = 'RESET_UAS_STATE';
export const SET_UAS_FILE_LIST = 'SET_UAS_FILE_LIST';
export const RESET_UAS_FILE_LIST = 'RESET_UAS_FILE_LIST';
export const GET_POLE_IDS = 'GET_POLE_IDS';
export const RESET_GET_POLE_IDS = 'RESET_GET_POLE_IDS';

const initialState = {
  fileList: [],
  isUploading: false,
  isDoneUploading: false,
  uploadError: null,
  filesDoneUploading: [],
  filesFailedUploading: [],
  invalidPole: true
};

const uas = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_UAS_FILE_LIST: {
      return {
        ...state,
        fileList: payload
      };
    }
    case RESET_UAS_FILE_LIST: {
      return {
        ...state,
        fileList: initialState.fileList
      };
    }
    case SET_UAS_UPLOADING:
      return {
        ...state,
        isUploading: payload
      };
    case DONE_UPLOADING: {
      return {
        ...state,
        isDoneUploading: payload
      };
    }
    case SET_UAS_ERROR: {
      return {
        ...state,
        uploadError: payload
      };
    }
    case SET_UAS_DONE_UPLOADING: {
      return {
        ...state,
        filesDoneUploading: [...state.filesDoneUploading, payload]
      };
    }
    case RESET_UAS_DONE_UPLOADING: {
      return {
        ...state,
        filesDoneUploading: initialState.filesDoneUploading
      };
    }
    case RESET_UAS_STATE: {
      return {
        ...initialState
      };
    }
    case GET_POLE_IDS: {
      return {
        ...state,
        invalidPole: payload
      };
    }
    case RESET_GET_POLE_IDS: {
      return {
        ...state,
        invalidPole: true
      };
    }
    default:
      return state;
  }
};

export default uas;
