import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './routerHistory';
import { currentUserHasPermission } from './containers/utils/roleUtils';
import PERMISSIONS from './auth.config';
import { TABS } from './layout/navigation/config';

const {
  ACCESS,
  DS_REPORTS_R,
  DS_REPORTS_RW,
  CIP_RW,
  CIP_R,
  WSD_RW,
  WSD_R,
  UPLOADS_DATASET_RW,
  UPLOADS_DATASET_R,
  UPLOADS_DATASET_W,
  USER_UPLOADS_RW,
  USER_UPLOADS_R,
  USER_UPLOADS_W,
  POLES_IMAGES_ONLY_R,
  POLES_IMAGES_ONLY_RW,
  POLES_ASSETS_R,
  POLES_ASSETS_RW,
  POLES_DAMAGES_R,
  POLES_DAMAGES_RW,
  POLES_DIAR_QAQC_EXPORT_R,
  POLES_DIAR_QAQC_EXPORT_RW
} = PERMISSIONS;

const LazyInfrastructure = React.lazy(() =>
  import(/* webpackChunkName: "Infrastructure" */ './containers/infrastructure/Infrastructure')
);
const LazyReportsPage = React.lazy(() =>
  import(/* webpackChunkName: "ReportsPage" */ './containers/reports/ReportsPage')
);
const LazyWsdPage = React.lazy(() =>
  import(/* webpackChunkName: "ReportsPage" */ './containers/wsd/WsdPage')
);

const LazyBlankWsdImagePage = React.lazy(() =>
  import(/* webpackChunkName: "ReportsPage" */ './containers/wsd/BlankWsdImagePage')
);

const LazyUasPage = React.lazy(() =>
  import(/* webpackChunkName: "UasPage" */ './containers/uas/UasPage')
);

const LazyDatasetsPage = React.lazy(() =>
  import(/* webpackChunkName: "DatasetsPage" */ './containers/datasets/DatasetsPage')
);

export const getComponent = (component, props) => {
  switch (component) {
    case 'PolesInfrastructureComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyInfrastructure {...props} tab={TABS.STRUCTURES} />
        </Suspense>
      );
    case 'CipInfrastructureComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyInfrastructure {...props} tab={TABS.CIP} />
        </Suspense>
      );
    case 'QAQCInfrastructureComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyInfrastructure {...props} tab={TABS.QAQC} />
        </Suspense>
      );
    case 'DatasetPageComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyDatasetsPage {...props} />
        </Suspense>
      );

    case 'ReportsPageComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyReportsPage {...props} />
        </Suspense>
      );
    case 'WsdPageComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyWsdPage {...props} />
        </Suspense>
      );
    case 'BlankWsdImagePageComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyBlankWsdImagePage {...props} />
        </Suspense>
      );
    case 'UasPageComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyUasPage {...props} />
        </Suspense>
      );
    case 'LidarInfrastructureComponent':
      return (
        <Suspense fallback={<div>loading...</div>}>
          <LazyInfrastructure {...props} tab={TABS.STRUCTURES} />
        </Suspense>
      );
    default:
      return <h1>Page not found</h1>;
  }
};

const PrivateRoute = function ({ component: Component, permissions, path, ...rest }) {
  if (path === '/') {
    return <Redirect to={getHomeRedirectLocation()} />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        currentUserHasPermission(permissions) ? (
          getComponent(Component, props)
        ) : (
          <Redirect to="/notFound" />
        )
      }
    />
  );
};

const getHomeRedirectLocation = () => {
  if (
    currentUserHasPermission([
      POLES_IMAGES_ONLY_R.key,
      POLES_IMAGES_ONLY_RW.key,
      POLES_ASSETS_R.key,
      POLES_ASSETS_RW.key,
      POLES_DAMAGES_R.key,
      POLES_DAMAGES_RW.key,
      POLES_DIAR_QAQC_EXPORT_R.key,
      POLES_DIAR_QAQC_EXPORT_RW.key
    ])
  ) {
    return '/structures';
  }
  if (currentUserHasPermission([CIP_RW.key, CIP_R.key])) {
    return '/cip';
  }
  if (currentUserHasPermission([DS_REPORTS_R.key, DS_REPORTS_RW.key])) {
    return '/reports';
  }
  if (currentUserHasPermission([WSD_RW.key, WSD_R.key])) {
    return '/wsd';
  }
  if (
    currentUserHasPermission([
      POLES_IMAGES_ONLY_R.key,
      POLES_IMAGES_ONLY_RW.key,
      POLES_ASSETS_R.key,
      POLES_ASSETS_RW.key,
      POLES_DAMAGES_R.key,
      POLES_DAMAGES_RW.key,
      POLES_DIAR_QAQC_EXPORT_R.key,
      POLES_DIAR_QAQC_EXPORT_RW.key
    ])
  ) {
    return 'qaqc';
  }

  return '/notFound';
};

const router = (
  <Router history={history}>
    <Switch>
      <PrivateRoute exact path="/" permissions={ACCESS.key} />
      <PrivateRoute
        exact
        path={['/cip', '/cip/pole/:poleId', '/cip/lidar/:lidarId']}
        component="CipInfrastructureComponent"
        permissions={[CIP_R.key, CIP_RW.key]}
      />
      <PrivateRoute
        exact
        path={['/structures', '/structures/structure/:poleId', '/structures/lidar/:lidarId']}
        component="PolesInfrastructureComponent"
        permissions={[
          POLES_IMAGES_ONLY_R.key,
          POLES_IMAGES_ONLY_RW.key,
          POLES_ASSETS_R.key,
          POLES_ASSETS_RW.key,
          POLES_DAMAGES_R.key,
          POLES_DAMAGES_RW.key,
          POLES_DIAR_QAQC_EXPORT_R.key,
          POLES_DIAR_QAQC_EXPORT_RW.key
        ]}
      />
      <Route
        path="/home"
        component={({ location }) => (
          <Redirect to={{ ...location, pathname: location.pathname.replace('home', '') }} />
        )}
      />
      <Route
        path="/poles/pole"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace('poles/pole', 'structures/structure')
            }}
          />
        )}
      />
      <Route
        path="/poles"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace(/poles/, 'structures')
            }}
          />
        )}
      />
      <PrivateRoute
        exact
        path={['lidar', '/lidar/:lidarId']}
        component="LidarInfrastructureComponent"
        permissions={[
          POLES_IMAGES_ONLY_R.key,
          POLES_IMAGES_ONLY_RW.key,
          POLES_ASSETS_R.key,
          POLES_ASSETS_RW.key,
          POLES_DAMAGES_R.key,
          POLES_DAMAGES_RW.key,
          POLES_DIAR_QAQC_EXPORT_R.key,
          POLES_DIAR_QAQC_EXPORT_RW.key
        ]}
      />
      <PrivateRoute
        exact
        path="/reports"
        component="ReportsPageComponent"
        permissions={[DS_REPORTS_R.key, DS_REPORTS_RW.key]}
      />
      <PrivateRoute
        exact
        path="/datasets"
        component="DatasetPageComponent"
        permissions={[UPLOADS_DATASET_RW.key, UPLOADS_DATASET_R.key, UPLOADS_DATASET_W.key]}
      />
      <PrivateRoute
        exact
        path={['/qaqc', '/qaqc/pole/:poleId', '/qaqc/lidar/:lidarId']}
        component="QAQCInfrastructureComponent"
        permissions={[
          POLES_IMAGES_ONLY_R.key,
          POLES_IMAGES_ONLY_RW.key,
          POLES_ASSETS_R.key,
          POLES_ASSETS_RW.key,
          POLES_DAMAGES_R.key,
          POLES_DAMAGES_RW.key,
          POLES_DIAR_QAQC_EXPORT_R.key,
          POLES_DIAR_QAQC_EXPORT_RW.key
        ]}
      />
      <PrivateRoute
        exact
        path="/wsd"
        component="WsdPageComponent"
        permissions={[WSD_R.key, WSD_RW.key]}
      />
      <PrivateRoute
        exact
        path="/wsdImage/:bucket/:key"
        component="BlankWsdImagePageComponent"
        permissions={[WSD_R.key, WSD_RW.key]}
      />
      <PrivateRoute
        exact
        path="/userupload"
        component="UasPageComponent"
        permissions={[USER_UPLOADS_RW.key, USER_UPLOADS_R.key, USER_UPLOADS_W.key]}
      />
      <Route render={() => getComponent()} />
    </Switch>
  </Router>
);

export default router;
