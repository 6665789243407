export const ROLES = {
    IIP_SPECIALISED_QEW: 'IIP – Specialised QEWs',
    IIP_PRD_VISUI_READONLY: 'IIP_PRD_VisUI_ReadOnly',
    IIP_PRD_DSUI_READWRITE: 'IIP_PRD_DSUI_ReadWrite',
    // TESTER role is only for test purposes, will be removed after more roles will be added
    TESTER: 'TESTER',
    CIP_PRD_READONLY: 'IIP_PRD_CIPUI_ReadOnly',
    CIP_PRD_READWRITE: 'IIP_PRD_CIPUI_ReadWrite',
    WSD_READONLY: 'IIP-IIP WSD ReadOnly-RO',
    IIP_PRD_UPLOAD_RW: 'IIP-PRD Upload-RW',
    IIP_DEV_UPLOAD_RW: 'IIP-DEV Upload-RW',
    IIP_PRD_VISUI_READWRITE: 'IIP_PRD_VisUI_ReadWrite', // remove when SV-iipautotst-p@sdge.com user will have one of the new roles
    IIP_ADMIN: 'IIP Admin',
    IIP_DEVELOPER: 'IIP Developer',
    IIP_TRANSMISSION_ACCESS: 'IIP Transmission Access',
    WSD_STAKEHOLDER: 'IIP WSD Stakeholder',
    IIP_CMG_CIP_REVIEWER: 'IIP CMG CIP Reviewer',
    IIP_IMAGE_REPOSITORY_CONTRIBUTOR: 'IIP Image Repository Contributor',
    IIP_DATA_VENDOR: 'IIP Data Vendor',
    IIP_ML_REVIEWER_ASSETS: 'IIP ML Reviewer - Assets',
    IIP_ML_REVIEWER_DAMAGES: 'IIP ML Reviewer - Damages',
    IIP_ML_CONTRIBUTOR_ASSETS: 'IIP ML Contributor - Assets',
    IIP_ML_CONTRIBUTOR_DAMAGES: 'IIP ML Contributor - Damages',
    IIP_CMP_REVIEWER: 'IIP CMP Reviewer',
    IIP_IMAGE_REPOSITORY_REVIEWER : 'IIP Image Repository Reviewer'
  };

  export const PERMISSION_MAPPING = {
    polesImages: 'POLES_IMAGES_ONLY',
    polesDamages: 'POLES_DAMAGES',
    polesDiarQaqcExport: 'POLES_DIAR_QAQC_EXPORT',
    polesAssets: 'POLES_ASSETS',
    cip: 'CIP',
    DSReports: 'DS_REPORTS',
    qaqc: 'QAQC',
    wsd: 'WSD',
    datasetUpload: 'UPLOADS_DATASET',
    userUpload: 'USER_UPLOADS',
    download: 'DOWNLOAD',
    transmission: 'TRANSMISSION_ACCESS',
    addDamage: 'ADD_DAMAGE',
    addFeedback: 'ADD_FEEDBACK'
  };
