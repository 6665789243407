export const LIDAR_LAYER_ID = 'lidar';

export const lidarLayerMetadata = {
  layerId: LIDAR_LAYER_ID,
  name: 'LiDAR'
};

export const LIDAR_MINIMUM_ZOOM = 12;
const lidarLayer = {
  minzoom: LIDAR_MINIMUM_ZOOM,
  id: LIDAR_LAYER_ID,
  type: 'fill',
  paint: {
    'fill-color': ['case', ['==', ['get', 'selected'], 'true'], '#1890FF', '#02152a'],
    'fill-opacity': ['case', ['==', ['get', 'selected'], 'true'], 0.9, 0.2],
    'fill-outline-color': ['case', ['==', ['get', 'selected'], 'true'], '#1890FF', '#02152a']
  }
};
export default lidarLayer;
