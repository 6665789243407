export const getSelectedItem = state => {
  if (
    state.map &&
    state.map.selectedFeature &&
    // TODO: why is there the second condition, when is it used?
    (state.map.selectedFeature.properties.Damages || state.view.tab)
  ) {
    return state.map.selectedFeature.properties;
  }
  return undefined;
};

export const getPictureRefsForSelectedItem = state =>
  getSelectedItem(state) &&
  getSelectedItem(state).PictureBatches &&
  getSelectedItem(state).PictureBatches.length > 0 &&
  getSelectedItem(state).PictureBatches[0].PictureRefs
    ? getSelectedItem(state).PictureBatches[0].PictureRefs
    : undefined;

export const getDamagesForSelectedItem = state =>
  getSelectedItem(state) ? getSelectedItem(state).Damages : undefined;

export const getAssetsForSelectedItem = state =>
  getSelectedItem(state) ? getSelectedItem(state).Assets : undefined;

export const getPicureRefsFromItem = item =>
  item ? item.PictureBatches[0].PictureRefs : undefined;

export const getDamagesFromItem = item => (item ? item.Damages : undefined);

export const getAssetsFromItem = item => (item ? item.Assets : undefined);

export const isBoundingBoxEditTurnedOn = state => state.infrastructure.isBoundingBoxEditTurnedOn;

export const getFilters = state => state.infrastructure.filters;

export const isBigImageVisibleSelector = state => state.infrastructure.isBigImageVisible;

export const getSmallImageSelector = state => state.infrastructure.smallImage;

export const isFilterVisible = state => state.infrastructure.isFilterVisible;

export const getLastGeoLocation = state => state.infrastructure.lastGeoLocation;

export const isSearchingWithGeoCoords = state => state.infrastructure.filters.searchWithGeoCoords;

export const isFireDistrictsSelector = state => state.infrastructure.fireDistricts;

export const isOperationalDistrictsSelector = state => state.infrastructure.operationalDistricts;

export const isBoundingBoxVisibleSelector = state => state.infrastructure.isBoundingBoxVisible;

export const isSatelliteDistrictsSelector = state => state.infrastructure.satelliteDistricts;

export const isCoverageDistrictsSelector = state => state.infrastructure.coverageDistricts;

export const getImageUrlSelector = (state, image, width) =>
  state.infrastructure.imageUrlMap[image.Path + (width || 'original')];
