export const DISTRICTS_SOURCE_ID = 'districts';
export const DISTRICTSMAP_ID = 'districts';

export const districtsMapMetadata = {
  layerId: DISTRICTSMAP_ID,
  name: 'Districts'
};

const MAX_ZOOM_LEVEL = 10;
const districtsmapLayer = {
  maxzoom: MAX_ZOOM_LEVEL,
  id: DISTRICTSMAP_ID,
  type: 'fill',
  paint: {
    'fill-color': [
      'match',
      ['get', 'id'],
      'NC',
      'red',
      'BC',
      'magenta',
      'ME',
      'DarkOrange',
      'OC',
      'LawnGreen',
      'CM',
      'blue',
      'RA',
      'green',
      'EA',
      'indigo',
      'NE',
      'black',
      'white'
    ],
    'fill-opacity': 0.3
  }
};
export default districtsmapLayer;
