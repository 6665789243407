import REPORT_TABLE_FEEDBACK_QUANTITY from './constants';

export const SET_TABLE_LOADING = 'SET_TABLE_LOADING';
export const SET_REPORTS_FILTERS = 'SET_REPORTS_FILTERS';
export const SET_ROW_LOADING = 'SET_ROW_LOADING';
export const SET_FEEDBACKS = 'SET_FEEDBACKS';
export const ADD_FEEDBACKS = 'ADD_FEEDBACKS';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const SET_EXPORT_FINISHED = 'SET_EXPORT_FINISHED';
export const RESET_REPORTS_FILTERS = 'RESET_REPORTS_FILTERS';
export const OPEN_REPORT_DETAIL = 'OPEN_REPORT_DETAIL';
export const CLOSE_REPORT_DETAIL = 'CLOSE_REPORT_DETAIL';
export const LOAD_FEEDBACKS_EXPORT = 'LOAD_FEEDBACKS_EXPORT';
export const RESET_FEEDBACKS_OFFSET_LIMIT = 'RESET_FEEDBACKS_OFFSET_LIMIT';
export const SET_EXPORT_LOADING = 'SET_EXPORT_LOADING';

const initialState = {
  feedbacksById: {},
  loading: false,
  loadingExport: false,
  reportDetailOpened: false,
  reportDetailData: null,
  feedbacksExportBlob: null,
  limit: REPORT_TABLE_FEEDBACK_QUANTITY.LIMIT,
  offset: 0,
  filters: {
    asset: [],
    assetType: [],
    vendor: [],
    configuration: [],
    material: [],
    groupId: '',
    damage: [],
    damaged: null,
    facilityId: '',
    imageName: '',
    newBoundingBox: null,
    verificationStatus: []
  },
  rowLoading: false
};
const reports = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TABLE_LOADING:
      return { ...state, loading: payload };
    case SET_REPORTS_FILTERS:
      return { ...state, filters: payload };
    case OPEN_REPORT_DETAIL:
      return { ...state, reportDetailData: payload, reportDetailOpened: true };
    case CLOSE_REPORT_DETAIL:
      return { ...state, reportDetailData: null, reportDetailOpened: false };
    case SET_ROW_LOADING:
      return { ...state, rowLoading: payload };
    case LOAD_FEEDBACKS_EXPORT:
      return { ...state, feedbacksExportBlob: payload };
    case SET_FEEDBACKS: {
      return {
        ...state,
        offset: state.offset + REPORT_TABLE_FEEDBACK_QUANTITY.LIMIT,
        feedbacksById: {
          ...payload
        }
      };
    }
    case ADD_FEEDBACKS: {
      return {
        ...state,
        offset: state.offset + REPORT_TABLE_FEEDBACK_QUANTITY.LIMIT,
        feedbacksById: {
          ...state.feedbacksById,
          ...payload
        }
      };
    }
    case SET_FEEDBACK:
      return {
        ...state,
        feedbacksById: {
          ...state.feedbacksById,
          payload
        }
      };
    case SET_EXPORT_FINISHED:
      return {
        ...state,
        feedbacksExportBlob: initialState.feedbacksExportBlob,
        loadingExport: false
      };
    case RESET_REPORTS_FILTERS:
      return { ...state, filters: initialState.filters };
    case RESET_FEEDBACKS_OFFSET_LIMIT:
      return { ...state, feedbacksById: {}, limit: payload.LIMIT, offset: 0 };
    case SET_EXPORT_LOADING:
      return { ...state, loadingExport: payload };
    default:
      return state;
  }
};
export default reports;
