export const SET_WSD_EXPORT_FILE = 'SET_WSD_EXPORT_FILE';
export const SET_WSD_EXPORT_FINISHED = 'SET_WSD_EXPORT_FINISHED';
export const SET_URL = 'SET_URL';
export const SET_WSD_LOADER = 'SET_WSD_LOADER';

const initialState = {
  wsdExportFile: null,
  wsdUrl: null,
  isLoading: false
};

const wsd = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_WSD_EXPORT_FILE:
      return { ...state, wsdExportFile: payload };
    case SET_URL:
      return { ...state, wsdUrl: payload };
    case SET_WSD_EXPORT_FINISHED:
      return {
        ...state,
        wsdExportFile: initialState.wsdExportFile,
        wsdUrl: initialState.wsdUrl,
        isLoading: initialState.isLoading
      };
    case SET_WSD_LOADER:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};

export default wsd;
