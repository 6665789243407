/**
 * Adds new feedback in damages in poleDetail and returns new object
 * @param {any} poleDetail
 * @param {string} damageId
 * @param {any} updatedData
 * @returns {any}
 */
export const updateDamageStatus = (poleDetail, damageId, updatedData) => {
  const newPoleDetail = JSON.parse(JSON.stringify(poleDetail));
  const damageIdx = poleDetail.Damages.findIndex(dmg => dmg.Id === damageId);
  if (damageIdx >= 0) {
  const updatedDamage = {
    ...poleDetail.Damages[damageIdx],
    Status: 'REVIEWED',
    Feedbacks: [
      ...poleDetail.Damages[damageIdx].Feedbacks,
      { ...updatedData, CreatedAt: Date.now() }
    ]
  };
  newPoleDetail.Damages[damageIdx] = updatedDamage;
}
  return newPoleDetail;
};

/**
 * Updated discrepancy in pole Detail, so the status for sidebar is correct
 * @param {any} poleDetail
 * @param {string} discrepancyLabel
 * @param {any} updatedData
 * @returns {any}
 */
export const updateDiscrepancy = (poleDetail, discrepancyLabel, updatedData) => {
    const newPoleDetail = JSON.parse(JSON.stringify(poleDetail));
    const discrepancyId = poleDetail.Discrepancies.findIndex(dsc => dsc.label === discrepancyLabel);
    console.log(discrepancyId); // eslint-disable-line no-console
    if (discrepancyId >= 0) {
    const updatedDiscrepancy = {
        ... poleDetail.Discrepancies[discrepancyId],
        status: updatedData.Feedback,
        comment: updatedData.Comment,
        severity: updatedData.Severity,
        feedback_at: Date.now(),
        feedback_by: updatedData.Reviewer
    }
    newPoleDetail.Discrepancies[discrepancyId] = updatedDiscrepancy;}
    return newPoleDetail
};
