const FIRE_THREAT = {
  TIER_1: 1,
  TIER_2: 2,
  TIER_3: 3
};

const { REACT_APP_ENV } = process.env;

export default {
  APIGateway: `${process.env.REACT_APP_API_URL}/${REACT_APP_ENV}/`,
  FIRE_THREAT,
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_AUTH_POOL_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'phone',
        'profile',
        process.env.REACT_APP_AUTH_SCOPE
      ],
      redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGN_OUT,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
};
