import { TABS } from '../../layout/navigation/config';
import { SHOW_BIG_IMAGE } from '../constants/actionTypes';

export const SET_POTREE_VIEW = 'SET_POTREE_VIEW';
export const SET_OPENED_TAB = 'SET_OPENED_TAB';

const initialState = {
  potreeView: undefined, // options 'splitScreen', 'fullScreen'
  tab: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_POTREE_VIEW:
      return { ...state, potreeView: payload };
    case SET_OPENED_TAB:
      if ([TABS.WSD, TABS.UAS, TABS.REPORTS, TABS.DATASETS].includes(payload)) {
        return { ...state, potreeView: null, tab: payload };
      }
      return { ...state, tab: payload };
    case SHOW_BIG_IMAGE: // should hide when full screen image gallery is turned on
      return { ...state, potreeView: null };
    default:
      return state;
  }
};

export const setOpenedTab = tab => ({
  type: SET_OPENED_TAB,
  payload: tab
});

const setPotreeView = viewOption => ({
  type: SET_POTREE_VIEW,
  payload: viewOption
});

const hidePotreeView = () => ({
  type: SET_POTREE_VIEW,
  payload: null
});

export const actions = {
  setPotreeView,
  hidePotreeView,
  setOpenedTab
};

export default reducer;
