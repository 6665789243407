import messages from '../../../utils/messages';
import {
  STRUCTURES_LAYER_ID,
  STRUCTURES_CLICKED_LAYER_ID
} from './StructuresLayer/structures-styles';
import { CIP_LAYER_ID, CIP_CLICKED_LAYER_ID } from './CIPLayer/cip-styles';
import { QAQC_LAYER_ID, QAQC_CLICKED_LAYER_ID } from './QAQCLayer/qaqc-styles';
import { DISTRICTSMAP_ID } from './DistrictsMap/districtsMap-style';
import { HEATMAP_ID } from './HeatMap/heatMap-style';
import { LIDAR_LAYER_ID, LIDAR_MINIMUM_ZOOM } from './LidarMap/lidarMap-style';

// for IT mapbox to switch cursor
const interactiveLayers = [
  DISTRICTSMAP_ID,
  STRUCTURES_LAYER_ID,
  CIP_LAYER_ID,
  QAQC_LAYER_ID,
  LIDAR_LAYER_ID
];

const tabLayers = [STRUCTURES_LAYER_ID, CIP_LAYER_ID, QAQC_LAYER_ID];

const infraItemTypes = [
  messages.camera,
  messages.camera360,
  messages.lampPost,
  messages.server,
  messages.poleWood,
  messages.poleSteel,
  messages.poleGroup
];

export {
  HEATMAP_ID,
  DISTRICTSMAP_ID,
  LIDAR_LAYER_ID,
  STRUCTURES_LAYER_ID,
  STRUCTURES_CLICKED_LAYER_ID,
  CIP_LAYER_ID,
  CIP_CLICKED_LAYER_ID,
  QAQC_LAYER_ID,
  QAQC_CLICKED_LAYER_ID,
  LIDAR_MINIMUM_ZOOM,
  interactiveLayers,
  tabLayers,
  infraItemTypes
};
