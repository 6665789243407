import PERMISSIONS from '../../../auth.config';
import store from '../../../redux/store';
import { PERMISSION_MAPPING } from '../../../rbac/constants';

let ENV_PERMISSIONS;

export const currentUserHasPermission = permissions =>
  permissions.filter(permission => {
    if (!ENV_PERMISSIONS) ENV_PERMISSIONS = mapEnvPermissions();
    return ENV_PERMISSIONS[permission]?.roles.some(
      role =>
        store
          .getState()
          .main.roles.findIndex(userRole => userRole.toLowerCase() === role.toLowerCase()) > -1
    );
  }).length > 0;

export const mapRolesToPermissions = (permissions, role, rolePermissions) => {
  const keys = Object.keys(rolePermissions);
  const perm = permissions;
  keys.forEach(roleKey => {
    const map = `${PERMISSION_MAPPING[roleKey]}_${rolePermissions[roleKey]}`;
    Object.prototype.hasOwnProperty.call(perm, map)
      ? perm[map].roles.push(role)
      : (perm[map] = { key: map, roles: [role] });
  });
  return perm;
};

export const mapEnvPermissions = () => {
  const REACT_APP_ENV = process.env.REACT_APP_ENV !== undefined ? process.env.REACT_APP_ENV : 'dev';
  // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-dynamic-require, global-require
  let permissionsConfig;
  if (process.env.NODE_ENV === 'UT') {
    // temporary fix, but we should not include config and use unit tests to test content of config outside code base
    // eslint-disable-next-line import/no-dynamic-require, global-require
    permissionsConfig = require(`../../../../../infra/config/api-permissions/${
      REACT_APP_ENV !== 'sbx' ? REACT_APP_ENV : 'dev'
    }-permissions.json`);
  } else {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    permissionsConfig = require(`../../../../../infra/config/api-permissions/${
      REACT_APP_ENV !== 'sbx' ? REACT_APP_ENV : 'tst'
    }-permissions.json`);
  }
  const CONFIG = permissionsConfig;

  const keys = Object.keys(CONFIG);
  let res = {};

  keys.forEach(key => {
    res = mapRolesToPermissions(res, key, CONFIG[key]);
  });
  return {
    ...{ ACCESS: PERMISSIONS.ACCESS },
    ...res
  };
};

export default currentUserHasPermission;
