import {
  SET_RESPONSIVE,
  SET_LOADER,
  SET_LOCAL_LOADER,
  SET_LOCAL_LOADERS
} from '../../redux/constants/actionTypes';

const initialState = {
  global: 0,
  local: {}
};

const adjustLoader = (original, isLoading) =>
  isLoading ? original + 1 : Math.max(original - 1, 0);

export const loaders = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        global: adjustLoader(state.global, payload.isLoading)
      };
    case SET_LOCAL_LOADER: {
      const newLoaderValue = adjustLoader(state.local[payload.name] || 0, payload.isLoading);
      return {
        ...state,
        local: { ...state.local, [payload.name]: newLoaderValue }
      };
    }
    case SET_LOCAL_LOADERS: {
      const newLoaders = {};
      payload.names.forEach(name => {
        newLoaders[name] = adjustLoader(state.local[name] || 0, payload.isLoading);
      });

      return {
        ...state,
        local: { ...state.local, ...newLoaders }
      };
    }
    default:
      return state;
  }
};

const initialStateResponsive = {
  mode: '',
  size: ''
};

export const responsive = (state = initialStateResponsive, { type, payload }) => {
  switch (type) {
    case SET_RESPONSIVE:
      return { ...state, ...payload };

    default:
      return state;
  }
};
