import { ROLES } from './rbac/constants';

const PERMISSIONS = {
  ACCESS: {
    key: 'ACCESS',
    roles: [
      ROLES.IIP_SPECIALISED_QEW,
      ROLES.IIP_PRD_VISUI_READONLY,
      ROLES.CIP_PRD_READONLY,
      ROLES.CIP_PRD_READWRITE,
      ROLES.WSD_READONLY,
      ROLES.IIP_PRD_VISUI_READWRITE, // remove when SV-iipautotst-p@sdge.com will have one of the new roles
      ROLES.IIP_PRD_UPLOAD_RW,
      ROLES.IIP_DEV_UPLOAD_RW,
      ROLES.POLES_IMAGES_ONLY_RW,
      ROLES.POLES_IMAGES_ONLY_R,
      ROLES.POLES_DAMAGES_RW,
      ROLES.POLES_DAMAGES_R,
      ROLES.POLES_DIAR_QAQC_EXPORT_RW,
      ROLES.POLES_DIAR_QAQC_EXPORT_R,
      ROLES.POLES_ASSETS_RW,
      ROLES.POLES_ASSETS_R,
      ROLES.CIP_RW,
      ROLES.CIP_R,
      ROLES.DS_REPORTS_RW,
      ROLES.DS_REPORTS_R,
      ROLES.QAQC_RW,
      ROLES.QAQC_R,
      ROLES.WSD_RW,
      ROLES.WSD_R,
      ROLES.UPLOADS_DATASET_RW,
      ROLES.UPLOADS_DATASET_R,
      ROLES.UPLOADS_DATASET_W,
      ROLES.USER_UPLOADS_RW,
      ROLES.USER_UPLOADS_R,
      ROLES.USER_UPLOADS_W,
      ROLES.DOWNLOAD_R,
      ROLES.TRANSMISSION_ACCESS_RW,
      ROLES.TRANSMISSION_ACCESS_R,
      ROLES.ADD_DAMAGE_RW,
      ROLES.ADD_DAMAGE_R,
      ROLES.ADD_FEEDBACK_RW,
      ROLES.ADD_FEEDBACK_R
    ]
  },
  VIEW_DATA_SCIENTIST: {
    key: 'VIEW_DATA_SCIENTIST',
    roles: [ROLES.IIP_PRD_DSUI_READWRITE]
  },
  GIVE_FEEDBACK: {
    key: 'GIVE_FEEDBACK',
    roles: [ROLES.IIP_PRD_VISUI_READWRITE]
  },
  IIP_ACCESS: {
    key: 'IIP_ACCESS',
    roles: [ROLES.IIP_SPECIALISED_QEW, ROLES.IIP_PRD_VISUI_READONLY, ROLES.IIP_PRD_VISUI_READWRITE]
  },
  CIP_ACCESS: {
    key: 'CIP_ACCESS',
    roles: [ROLES.CIP_PRD_READONLY, ROLES.CIP_PRD_READWRITE]
  },
  QAQC_ACCESS: {
    key: 'QAQC_ACCESS',
    roles: [ROLES.IIP_PRD_VISUI_READONLY, ROLES.IIP_PRD_VISUI_READWRITE]
  },
  WSD_ACCESS: {
    key: 'WSD_ACCESS',
    roles: [ROLES.WSD_READONLY]
  },
  UAS_ACCESS: {
    key: 'UAS_ACCESS',
    roles: [ROLES.IIP_PRD_UPLOAD_RW, ROLES.IIP_DEV_UPLOAD_RW]
  },
  DATASET_ACCESS: {
    key: 'DATASET_ACCESS',
    roles: [ROLES.IIP_PRD_UPLOAD_RW, ROLES.IIP_DEV_UPLOAD_RW]
  },
  POLES_IMAGES_ONLY_RW: {
    key: 'POLES_IMAGES_ONLY_RW',
    roles: [ROLES.IIP_ADMIN]
  },
  POLES_IMAGES_ONLY_R: {
    key: 'POLES_IMAGES_ONLY_R',
    roles: [
      ROLES.IIP_DEVELOPER,
      ROLES.IIP_CMG_CIP_REVIEWER,
      ROLES.IIP_IMAGE_REPOSITORY_CONTRIBUTOR,
      ROLES.IIP_ML_CONTRIBUTOR_ASSETS,
      ROLES.IIP_ML_REVIEWER_DAMAGES,
      ROLES.IIP_ML_REVIEWER_ASSETS,
      ROLES.IIP_CMP_REVIEWER
    ]
  },
  POLES_DAMAGES_RW: {
    key: 'POLES_DAMAGES_RW',
    roles: [ROLES.IIP_PRD_VISUI_READWRITE, ROLES.IIP_ADMIN, ROLES.IIP_ML_CONTRIBUTOR_DAMAGES]
  },
  POLES_DAMAGES_R: {
    key: 'POLES_DAMAGES_R',
    roles: [ROLES.IIP_DEVELOPER, ROLES.IIP_ML_REVIEWER_DAMAGES, ROLES.IIP_ML_CONTRIBUTOR_ASSETS, ROLES.IIP_CMP_REVIEWER]
  },
  POLES_DIAR_QAQC_EXPORT_RW: {
    key: 'POLES_DIAR_QAQC_EXPORT_RW',
    roles: [ROLES.IIP_PRD_VISUI_READWRITE, ROLES.IIP_ADMIN]
  },
  POLES_DIAR_QAQC_EXPORT_R: {
    key: 'POLES_DIAR_QAQC_EXPORT_R',
    roles: [ROLES.IIP_DEVELOPER, ROLES.IIP_CMP_REVIEWER]
  },
  POLES_ASSETS_RW: {
    key: 'POLES_ASSETS_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_ML_CONTRIBUTOR_ASSETS, ROLES.IIP_ML_CONTRIBUTOR_DAMAGES]
  },
  POLES_ASSETS_R: {
    key: 'POLES_ASSETS_R',
    roles: [ROLES.IIP_DEVELOPER, ROLES.IIP_ML_REVIEWER_ASSETS, ROLES.IIP_ML_REVIEWER_DAMAGES, ROLES.IIP_CMP_REVIEWER]
  },
  CIP_RW: {
    key: 'CIP_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_CMG_CIP_REVIEWER]
  },
  CIP_R: {
    key: 'CIP_R',
    roles: [ROLES.IIP_DEVELOPER]
  },
  DS_REPORTS_RW: {
    key: 'DS_REPORTS_RW',
    roles: [ROLES.IIP_ADMIN]
  },
  DS_REPORTS_R: {
    key: 'DS_REPORTS_R',
    roles: [ROLES.IIP_DEVELOPER]
  },
  QAQC_RW: {
    key: 'QAQC_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_PRD_VISUI_READWRITE]
  },
  QAQC_R: {
    key: 'QAQC_R',
    roles: [ROLES.IIP_DEVELOPER, ROLES.IIP_CMP_REVIEWER]
  },
  WSD_RW: {
    key: 'WSD_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.WSD_STAKEHOLDER]
  },
  WSD_R: {
    key: 'WSD_R',
    roles: [ROLES.IIP_ADMIN]
  },
  UPLOADS_DATASET_RW: {
    key: 'UPLOADS_DATASET_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_IMAGE_REPOSITORY_CONTRIBUTOR]
  },
  UPLOADS_DATASET_R: {
    key: 'UPLOADS_DATASET_R',
    roles: [ROLES.IIP_DEVELOPER]
  },
  UPLOADS_DATASET_W: {
    key: 'UPLOADS_DATASET_W',
    roles: []
  },
  USER_UPLOADS_RW: {
    key: 'USER_UPLOADS_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_IMAGE_REPOSITORY_CONTRIBUTOR, ROLES.IIP_ML_REVIEWER_ASSETS, ROLES.IIP_ML_REVIEWER_DAMAGES, ROLES.IIP_ML_CONTRIBUTOR_ASSETS, ROLES.IIP_ML_CONTRIBUTOR_DAMAGES, ROLES.IIP_CMP_REVIEWER, ROLES.IIP_IMAGE_REPOSITORY_REVIEWER]
  },
  USER_UPLOADS_R: {
    key: 'USER_UPLOADS_R',
    roles: [ROLES.IIP_DEVELOPER, ROLES.IIP_CMP_REVIEWER]
  },
  USER_UPLOADS_W: {
    key: 'USER_UPLOADS_W',
    roles: [ROLES.IIP_DATA_VENDOR]
  },
  DOWNLOAD_R: {
    key: 'DOWNLOAD_R',
    roles: [
      ROLES.IIP_ADMIN,
      ROLES.IIP_DEVELOPER,
      ROLES.IIP_CMG_CIP_REVIEWER,
      ROLES.IIP_IMAGE_REPOSITORY_CONTRIBUTOR,
      ROLES.IIP_ML_CONTRIBUTOR_ASSETS,
      ROLES.IIP_ML_CONTRIBUTOR_DAMAGES,
      ROLES.IIP_ML_REVIEWER_ASSETS,
      ROLES.IIP_ML_REVIEWER_DAMAGES,
      ROLES.IIP_CMP_REVIEWER
    ]
  },
  TRANSMISSION_ACCESS_RW: {
    key: 'TRANSMISSION_ACCESS_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_TRANSMISSION_ACCESS]
  },
  TRANSMISSION_ACCESS_R: {
    key: 'TRANSMISSION_ACCESS_R',
    roles: [ROLES.IIP_DEVELOPER, ROLES.IIP_CMG_CIP_REVIEWER]
  },
  ADD_DAMAGE_RW: {
    key: 'ADD_DAMAGE_RW',
    roles: [ROLES.IIP_ADMIN, ROLES.IIP_ML_CONTRIBUTOR_ASSETS, ROLES.IIP_ML_CONTRIBUTOR_DAMAGES]
  },
  ADD_DAMAGE_R: {
    key: 'ADD_DAMAGE_R',
    roles: [ROLES.IIP_DEVELOPER]
  },
  ADD_FEEDBACK_RW: {
    key: 'ADD_FEEDBACK_RW',
    roles: [
      ROLES.IIP_ADMIN,
      ROLES.IIP_ML_CONTRIBUTOR_ASSETS,
      ROLES.IIP_ML_CONTRIBUTOR_DAMAGES,
      ROLES.IIP_CMG_CIP_REVIEWER,
      ROLES.IIP_PRD_VISUI_READWRITE
    ]
  },
  ADD_FEEDBACK_R: {
    key: 'ADD_FEEDBACK_R',
    roles: [ROLES.IIP_DEVELOPER]
  }
};

export default PERMISSIONS;
