import NoPredictionIcon from './noprediction.png';
import PredictedDiscrepanciesIcon from './predictedDiscrepancies.png';
import PredictedMatchesIcon from './predictedMatches.png';
import VerifiedDiscrepanciesIcon from './verifiedDiscrepancies.png';
import VerifiedMatchesIcon from './verifiedMatches.png';

export const CIP_SOURCE_ID = 'cip_source';
export const CIP_LAYER_ID = 'cip';

export const cipLayerMetadata = {
  layerId: CIP_LAYER_ID,
  name: 'CIP',
  // color:,
  // icon:,
  layerSymbols: [
    {
      symbolName: 'NoPredictionIcon',
      symbol: NoPredictionIcon
    },
    { symbolName: 'PredictedDiscrepanciesIcon', symbol: PredictedDiscrepanciesIcon },
    { symbolName: 'PredictedMatchesIcon', symbol: PredictedMatchesIcon },
    { symbolName: 'VerifiedDiscrepanciesIcon', symbol: VerifiedDiscrepanciesIcon },
    { symbolName: 'VerifiedMatchesIcon', symbol: VerifiedMatchesIcon }
  ]
};

export const cipLayer = {
  id: CIP_LAYER_ID,
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'Status'], 'NOT_PREDICTED'],
      'NoPredictionIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_PREDICTED'],
      'PredictedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_PREDICTED'],
      'PredictedMatchesIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_VERIFIED'],
      'VerifiedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_VERIFIED'],
      'VerifiedMatchesIcon',
      'black'
    ],
    'icon-size': 0.4,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': [
      'case',
      [
        '!',
        [
          'in',
          ['get', 'Status'],
          [
            'literal',
            [
              'NOT_PREDICTED',
              'DISCREPANCIES_PREDICTED',
              'MATCHES_PREDICTED',
              'DISCREPANCIES_VERIFIED',
              'MATCHES_VERIFIED'
            ]
          ]
        ]
      ],
      '',
      ['==', ['get', 'structureIdToggle'], true],
      ['get', 'FacilityId'],
      ''
    ],
    'text-size': 12,
    'text-variable-anchor': ['bottom-left'],
    'text-radial-offset': 1.25,
    'text-justify': 'auto'
  },
  minzoom: 10
};

export const CIP_CLICKED_SOURCE_ID = 'cip_clicked_source';
export const CIP_CLICKED_LAYER_ID = 'cip_clicked';

export const cipClickedLayer = {
  id:  CIP_CLICKED_LAYER_ID,
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'Status'], 'NOT_PREDICTED'],
      'NoPredictionIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_PREDICTED'],
      'PredictedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_PREDICTED'],
      'PredictedMatchesIcon',
      ['==', ['get', 'Status'], 'DISCREPANCIES_VERIFIED'],
      'VerifiedDiscrepanciesIcon',
      ['==', ['get', 'Status'], 'MATCHES_VERIFIED'],
      'VerifiedMatchesIcon',
      'black'
    ],
    'icon-size': 0.8,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': [
      'case',
      [
        '!',
        [
          'in',
          ['get', 'Status'],
          [
            'literal',
            [
              'NOT_PREDICTED',
              'DISCREPANCIES_PREDICTED',
              'MATCHES_PREDICTED',
              'DISCREPANCIES_VERIFIED',
              'MATCHES_VERIFIED'
            ]
          ]
        ]
      ],
      '',
      ['==', ['get', 'structureIdToggle'], true],
      ['get', 'FacilityId'],
      ''
    ],
    'text-size': 12,
    'text-variable-anchor': ['bottom-left'],
    'text-radial-offset': 1.25,
    'text-justify': 'auto'
  },
  minzoom: 10
};
