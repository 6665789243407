export const LOADERS = {
  INFRA: 'infra',
  INFRA_SINGLE: 'infrasingle',
  MAP: 'map',
  SETTINGS: 'settings',
  GLOBAL: 'global',
  REPORTS: 'reports',
  POLES: 'poles',
  ADD_DAMAGE: 'add_damage',
  SEND_FEEDBACK: 'sendFeedback',
  VERIFY_CIP: 'verifyCIP',
  CIP_HOLD: 'CIPHold',
  SAVE_ATTACHMENTS: 'saveAttachments',
  CIP_PANEL: 'cipPanel',
  QAQC_EXPORT: 'qaqcExport',
  COVERAGE: 'coverage',
  FLAG_PHOTOS: 'flagPhotos',
  ASSET_REVIEW: 'assetReview'
};

export const SIZES = {
  LG: 'lg',
  MD: 'md',
  MD_LANDSCAPE: 'mdlandscape',
  SM: 'sm',
  XS: 'xs'
};

export const MODES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop'
};
/** options for status added to image object to differentiate between states */
export const IMAGE_STATUS = {
  /** damage reviewed */
  REVIEWED: 'REVIEWED',
  /** damage waiting to review */
  TO_REVIEW: 'TO_REVIEW',
  /** no damage assigned to image */
  NO_DAMAGE: 'NO_DAMAGE',
  /** not specified error during parsing */
  ERROR: 'ERROR'
};

export const REVIEW = {
  VERIFIED: 'verified',
  REJECTED: 'rejected'
};
