import { SET_RESPONSIVE } from '../../redux/constants/actionTypes';
import { SIZES, MODES } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const setResponsive = size => ({
  type: SET_RESPONSIVE,
  payload: {
    size,
    mode: [SIZES.XS, SIZES.SM, SIZES.MD].includes(size) ? MODES.MOBILE : MODES.DESKTOP
  }
});
