// images
import SurfaceIcon from './Surface.png';
import SurfaceHoverIcon from './Surface_hover.png';
import OverheadDistributionIcon from './OverheadDistribution.png';
import OverheadDistributionHoverIcon from './OverheadDistribution_hover.png';
import OverheadTransmissionIcon from './OverheadTransmission.png';
import OverheadTransmissionHoverIcon from './OverheadTransmission_hover.png';
import SubsurfaceIcon from './Subsurface.png';
import SubsurfaceHoverIcon from './Subsurface_hover.png';

export const STRUCTURES_SOURCE_ID = 'structures_source';
export const STRUCTURES_LAYER_ID = 'structures';

export const structuresLayerMetadata = {
  layerId: STRUCTURES_LAYER_ID,
  name: 'Structures',
  // color: ,
  // icon: ,
  layerSymbols: [
    { symbolName: 'Surface', symbol: SurfaceIcon },
    { symbolName: 'OverheadDistribution', symbol: OverheadDistributionIcon },
    { symbolName: 'OverheadTransmission', symbol: OverheadTransmissionIcon },
    { symbolName: 'Subsurface', symbol: SubsurfaceIcon },
    { symbolName: 'Surface_hover', symbol: SurfaceHoverIcon },
    { symbolName: 'OverheadDistribution_hover', symbol: OverheadDistributionHoverIcon },
    { symbolName: 'OverheadTransmission_hover', symbol: OverheadTransmissionHoverIcon },
    { symbolName: 'Subsurface_hover', symbol: SubsurfaceHoverIcon }
  ]
};

export const structuresLayer = {
  id: STRUCTURES_LAYER_ID,
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'icon'], 'OverheadDistribution'],
      'OverheadDistribution',
      ['==', ['get', 'icon'], 'OverheadTransmission'],
      'OverheadTransmission',
      ['==', ['get', 'icon'], 'Surface'],
      'Surface',
      ['==', ['get', 'icon'], 'Subsurface'],
      'Subsurface',
      'black'
    ],
    'icon-size': 0.4,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': [
      'case',
      [
        '!',
        [
          'in',
          ['get', 'icon'],
          ['literal', ['OverheadDistribution', 'OverheadTransmission', 'Surface', 'Subsurface']]
        ]
      ],
      '',
      ['==', ['get', 'structureIdToggle'], true],
      ['get', 'FacilityId'],
      ''
    ],
    'text-size': 12,
    'text-variable-anchor': ['bottom-left'],
    'text-radial-offset': 1.25,
    'text-justify': 'auto'
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 1]
  },
  minzoom: 10
};

export const hoverLayoutChanges = {
  'icon-image': [
    'case',
    ['==', ['get', 'icon'], 'OverheadDistribution'],
    'OverheadDistribution_hover',
    ['==', ['get', 'icon'], 'OverheadTransmission'],
    'OverheadTransmission_hover',
    ['==', ['get', 'icon'], 'Surface'],
    'Surface_hover',
    ['==', ['get', 'icon'], 'Subsurface'],
    'Subsurface_hover',
    'black'
  ]
};

export const STRUCTURES_CLICKED_SOURCE_ID = 'structures_clicked_source';
export const STRUCTURES_CLICKED_LAYER_ID = 'structures_clicked';

export const structuresClickedLayer = {
  id: STRUCTURES_CLICKED_LAYER_ID,
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['==', ['get', 'icon'], 'OverheadDistribution'],
      'OverheadDistribution_hover',
      ['==', ['get', 'icon'], 'OverheadTransmission'],
      'OverheadTransmission_hover',
      ['==', ['get', 'icon'], 'Surface'],
      'Surface_hover',
      ['==', ['get', 'icon'], 'Subsurface'],
      'Subsurface_hover',
      'black'
    ],
    'icon-size': 1,
    'icon-ignore-placement': true,
    'text-field': [
      'case',
      [
        '!',
        [
          'in',
          ['get', 'icon'],
          ['literal', ['OverheadDistribution', 'OverheadTransmission', 'Surface', 'Subsurface']]
        ]
      ],
      '',
      ['==', ['get', 'structureIdToggle'], true],
      ['get', 'FacilityId'],
      ''
    ],
    'text-size': 12,
    'text-variable-anchor': ['bottom-left'],
    'text-radial-offset': 1.25,
    'text-justify': 'auto'
  },
  paint: {
    'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 1]
  },
  minzoom: 10
};

export const activeLayoutChanges = {
  'icon-image': [
    'case',
    ['==', ['get', 'icon'], 'OverheadDistribution'],
    'OverheadDistribution_hover',
    ['==', ['get', 'icon'], 'OverheadTransmission'],
    'OverheadTransmission_hover',
    ['==', ['get', 'icon'], 'Surface'],
    'Surface_hover',
    ['==', ['get', 'icon'], 'Subsurface'],
    'Subsurface_hover',
    'black'
  ],
  'icon-size': 1
};
