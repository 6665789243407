export const LOAD_TABLE_DATA = 'LOAD_TABLE_DATA';
export const LOAD_TABLE_DATA_FULL = 'LOAD_TABLE_DATA_FULL';
export const LOAD_PREPARED_DOWNLOAD_DATA = 'LOAD_PREPARED_DOWNLOAD_DATA';
export const SET_UNIQUE_DROPDOWN_DATA = 'SET_UNIQUE_DROPDOWN_DATA';
export const SET_TABLE_DATA_COUNT = 'SET_TABLE_DATA_COUNT';
export const SET_TABLE_DATA_LOADING = 'SET_TABLE_DATA_LOADING';
export const SET_UPLOADING_STATUS = 'SET_UPLOADING_STATUS';

export const initialState = {
  datasetsTable: [],
  datasetsTableFull: [],
  preparedDownloadsTable: [],
  allUniqueDropdownData: {
    allUniqueRegions: [],
    allUniqueCaptureTypes: [],
    allUniqueCircuits: [],
    allUniqueDataOwners: [],
    allUniqueUploadUsers: [],
    allUniqueDatasetTypes: [],
    allUniqueLineTypes: [],
    allUniqueFileTypes: [],
    allUniqueSources: [],
    allUniqueStatuses: []
  },
  datasetsTableRowCount: 0,
  datasetsTableLoading: false,
  isLidarUploading: false
};

const datasets = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_TABLE_DATA:
      return { ...state, datasetsTable: payload };
    case LOAD_TABLE_DATA_FULL:
      return { ...state, datasetsTableFull: payload };
    case LOAD_PREPARED_DOWNLOAD_DATA:
      return { ...state, preparedDownloadsTable: payload };
    case SET_UNIQUE_DROPDOWN_DATA:
      return { ...state, allUniqueDropdownData: payload };
    case SET_TABLE_DATA_COUNT:
      return { ...state, datasetsTableRowCount: payload };
    case SET_TABLE_DATA_LOADING:
      return { ...state, datasetsTableLoading: payload };
    case SET_UPLOADING_STATUS:
      return { ...state, isLidarUploading: payload };
    default:
      return state;
  }
};

export default datasets;
