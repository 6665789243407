import React from 'react';
import Icon, {
    ExclamationCircleOutlined,
    DatabaseOutlined,
    VerticalAlignTopOutlined
  } from '@ant-design/icons';

import sempraSvg from '../../images/SDGE.png';
import polesSvg from '../../images/polesSvg.svg';
import polesSvgSelected from '../../images/polesSvgSelected.svg';
import cipSvg from '../../images/cipSvg.svg';
import cipSvgSelected from '../../images/cipSvgSelected.svg';
import reportSvg from '../../images/reportSvg.svg';
import reportSvgSelected from '../../images/reportSvgSelected.svg';
import datasetSvg from '../../images/datasetSvg.svg';
import datasetSvgSelected from '../../images/datasetSvgSelected.svg';

import strings from '../../translate/strings.js';

import PERMISSIONS from '../../auth.config.js';

export const TABS = {
  HOME: '',
  INFRASTRUCTURE: 'InfrastructureList',
  AREAS: 'Areas',
  // POLES: 'Poles',
  STRUCTURES: 'Structures',
  CIP: 'Cip',
  POTREE: 'Potree',
  DATASETS: 'Datasets',
  REPORTS: 'Reports',
  FILTER: 'Filter',
  SIGNOUT: 'Sign out',
  ADMIN: 'Admin',
  QAQC: 'Qaqc',
  WSD: 'Wsd',
  UAS: 'User Upload'
};

const {
    DS_REPORTS_R,
    DS_REPORTS_RW,
    CIP_R,
    CIP_RW,
    QAQC_R,
    QAQC_RW,
    WSD_R,
    WSD_RW,
    UPLOADS_DATASET_RW,
    UPLOADS_DATASET_R,
    UPLOADS_DATASET_W,
    USER_UPLOADS_RW,
    USER_UPLOADS_R,
    USER_UPLOADS_W,
    POLES_IMAGES_ONLY_R,
    POLES_IMAGES_ONLY_RW,
    POLES_ASSETS_R,
    POLES_ASSETS_RW,
    POLES_DAMAGES_R,
    POLES_DAMAGES_RW,
    POLES_DIAR_QAQC_EXPORT_R,
    POLES_DIAR_QAQC_EXPORT_RW
  } = PERMISSIONS;

export const tabsConfig = (openedTab) => [
    {
      tab: TABS.HOME,
      url: '',
      icon: (
        <Icon className="sempraLogo" component={() => <img src={sempraSvg} alt="sempra logo" />} />
      ),
      lang: ''
    },
    {
      tab: TABS.STRUCTURES,
      icon: (
        <Icon
          className="polessvg"
          component={() => (
            <img
              src={openedTab !== TABS.STRUCTURES ? polesSvg : polesSvgSelected}
              alt="poles svg"
            />
          )}
        />
      ),
      lang: strings.sideBar.structures,
      permissions: [
        POLES_IMAGES_ONLY_R.key,
        POLES_IMAGES_ONLY_RW.key,
        POLES_ASSETS_R.key,
        POLES_ASSETS_RW.key,
        POLES_DAMAGES_R.key,
        POLES_DAMAGES_RW.key,
        POLES_DIAR_QAQC_EXPORT_R.key,
        POLES_DIAR_QAQC_EXPORT_RW.key
      ]
    },
    {
      tab: TABS.CIP,
      icon: (
        <Icon
          className="cipsvg"
          component={() => (
            <img src={openedTab !== TABS.CIP ? cipSvg : cipSvgSelected} alt="cip svg" />
          )}
        />
      ),
      lang: strings.sideBar.cip,
      permissions: [CIP_RW.key, CIP_R.key]
    },
    {
      tab: TABS.DATASETS,
      icon: (
        <Icon
          className="datasetsvg"
          component={() => (
            <img
              src={openedTab !== TABS.DATASETS ? datasetSvg : datasetSvgSelected}
              alt="dataset svg"
            />
          )}
        />
      ),
      lang: strings.sideBar.datasets,
      permissions: [UPLOADS_DATASET_RW.key, UPLOADS_DATASET_R.key, UPLOADS_DATASET_W.key]
    },
    {
      tab: TABS.REPORTS,
      icon: (
        <Icon
          className="reportsvg"
          component={() => (
            <img
              src={openedTab !== TABS.REPORTS ? reportSvg : reportSvgSelected}
              alt="report svg"
            />
          )}
        />
      ),
      lang: strings.sideBar.reports,
      permissions: [DS_REPORTS_R.key, DS_REPORTS_RW.key]
    },
    {
      tab: TABS.QAQC,
      icon: <ExclamationCircleOutlined />,
      lang: strings.sideBar.qaqc,
      permissions: [QAQC_R.key, QAQC_RW.key]
    },
    {
      tab: TABS.WSD,
      icon: <DatabaseOutlined />,
      lang: strings.sideBar.wsd,
      permissions: [WSD_R.key, WSD_RW.key]
    },
    {
      tab: TABS.UAS,
      icon: <VerticalAlignTopOutlined />,
      lang: strings.sideBar.uas
      ,
      permissions: [USER_UPLOADS_RW.key, USER_UPLOADS_R.key, USER_UPLOADS_W.key]
    },
    
  ];
