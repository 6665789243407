import {
  DYNAMIC_CONFIG,
  SET_AUTHENTICATED,
  SET_AUTHENTICATED_USER,
  SET_CUSTOM_STATE
} from '../constants/actionTypes';
import config from '../../config';

export const itemIsNotYetOpen = (items, newItem) =>
  items.filter(item => item.key === newItem.key).length === 0;

export const initialState = {
  dynamicConfig: {
    APIGateway: config.APIGateway
  },
  username: '',
  roles: [],
  isAuthenticated: false,
  customState: ''
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DYNAMIC_CONFIG:
      return { ...state, dynamicConfig: payload };
    case SET_AUTHENTICATED:
      return { ...state, isAuthenticated: payload };
    case SET_AUTHENTICATED_USER:
      return { ...state, username: payload.username, roles: payload.roles, isAuthenticated: true };
    case SET_CUSTOM_STATE:
      return { ...state, customState: payload };
    default:
      return state;
  }
};

const setAuthenticated = payload => ({
  type: SET_AUTHENTICATED,
  payload
});

const setAuthenticatedUser = (username, roles) => ({
  type: SET_AUTHENTICATED_USER,
  payload: { username, roles }
});

const setCustomState = state => ({ type: SET_CUSTOM_STATE, payload: state });

export const actions = {
  setAuthenticated,
  setAuthenticatedUser,
  setCustomState
};

export default reducer;
