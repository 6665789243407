/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { removeActiveLayer as removeActiveLayerAction } from '@innovation-toolkit/mapbox';

import {NavBar, mapActions} from '@innovation-toolkit/iip-ui';
import {TABS, tabsConfig} from './config.jsx';

import history from '../../routerHistory.js';
import { updateTab } from '../../utils/url.js';
import { currentUserHasPermission } from '../../containers/utils/roleUtils/index.js';

import { MODES } from '../../containers/sidebar/constants.js';
import { MODES as RESPONSIVE_MODES } from '../../containers/utils/constants.js';
import { LIDAR_LAYER_ID } from '../../containers/map/layers/constants.js';

import {
  setSidebarCollapsed as setSidebarCollapsedAction,
  setSidebarMode as setSidebarModeAction
} from '../../containers/sidebar/actions.js';
import {
  actions as viewActions,
  setOpenedTab as setOpenedTabAction
} from '../../redux/commonReducers/viewReducer.js';
import {
  resetItems as resetItemsAction,
  setFilterVisibility as setFilterVisibilityAction,
  resetLidarPolygons as resetLidarPolygonsAction,
  hideBigImage as hideBigImageAction
} from '../../containers/infrastructure/actions.js';
import { isBigImageVisibleSelector } from '../../containers/infrastructure/selectors.js';

function NavBarContainer({
  responsiveMode,
  openedTab,
  dynamicConfig,
  splitScreen,
  sidebarCollapsed,
  resetItems,
  setFilterVisibility,
  setOpenedTab,
  clearSelectedFeature,
  clearPopupFeature,
  setSidebarCollapsed,
  setSidebarMode,
  hidePotreeView,
  resetLidarPolygons,
  removeActiveLayer,
  hideBigImage,
  signOut,
  ...props
}) {
  const onMenuClickCleanup = (key) => {
    !sidebarCollapsed && setSidebarCollapsed(true);
    if (openedTab !== key) {
      setSidebarMode(MODES.DEFAULT);
      clearSelectedFeature();
      clearPopupFeature();
      resetItems();
      resetLidarPolygons();
      removeActiveLayer(LIDAR_LAYER_ID);
      setFilterVisibility(false);
      hideBigImage();

      // TODO: verify and refactor
      if (splitScreen && props.isBigPictureVisible) {
        props.setSidebarSpecialCss(!props.sidebarSpecialCss);
      }
    }
  };

  const onMenuTabClick = key => {
    // clicked on already opened screen
    if (key !== TABS.HOME && key === openedTab) {
      // map screens should toggle sidebar & hide image gallery
      if ([TABS.STRUCTURES, TABS.CIP, TABS.QAQC].includes(key)) {
        setSidebarCollapsed(!sidebarCollapsed);
        hideBigImage();
      }
    } else {
      switch (key) {
        case TABS.HOME:
        case 'home': {
          updateTab(history, '');
          hidePotreeView();
          break;
        }
        case TABS.STRUCTURES: {
          history.push({ pathname: '/structures', search: history.location.search });
          break;
        }
        case TABS.CIP: {
          history.push({ pathname: '/cip', search: history.location.search });
          break;
        }
        case TABS.DATASETS: {
          history.push(`/datasets`);
          break;
        }
        case TABS.REPORTS: {
          history.push('/reports');
          break;
        }
        case TABS.QAQC: {
          history.push({ pathname: '/qaqc', search: history.location.search });
          break;
        }
        case TABS.WSD: {
          history.push('/wsd');
          break;
        }
        case TABS.UAS: {
          history.push('/userupload');
          break;
        }
        default:
          history.push('/');
      }
    }
  };

  const onMobileMenuClick = () => {
    clearSelectedFeature();
    setOpenedTab('');
    setSidebarMode(MODES.DEFAULT);
  };

  return (
    <NavBar
      TABS={TABS}
      tabsConfig={tabsConfig(openedTab).filter(
        tab => !tab.permissions || currentUserHasPermission(tab.permissions)
      )}
      setOpenedTab={setOpenedTab}
      signOut={signOut}
      onMenuTabClick={onMenuTabClick}
      onMenuClickCleanup={onMenuClickCleanup}
      mobileMode={responsiveMode === RESPONSIVE_MODES.MOBILE}
      onMobileMenuClick={onMobileMenuClick}
    />
  );
}

NavBarContainer.propTypes = {
  openedTab: PropTypes.string.isRequired,
  dynamicConfig: PropTypes.object.isRequired
};

NavBarContainer.defaultProps = {};

const mapStateToProps = state => ({
  openedTab: state.view.tab,
  dynamicConfig: state.main.dynamicConfig,
  responsiveMode: state.responsive.mode,
  sidebarCollapsed: state.sidebar.collapsed,
  splitScreen: Boolean(state.view.potreeView === 'splitScreen'),
  isBigPictureVisible: isBigImageVisibleSelector(state)
});

const mapDispatchToProps = {
  resetItems: resetItemsAction,
  setFilterVisibility: setFilterVisibilityAction,
  setOpenedTab: setOpenedTabAction,
  clearSelectedFeature: mapActions.clearSelectedFeature,
  clearPopupFeature: mapActions.clearPopupFeature,
  setSidebarCollapsed: setSidebarCollapsedAction,
  setSidebarMode: setSidebarModeAction,
  hidePotreeView: viewActions.hidePotreeView,
  resetLidarPolygons: resetLidarPolygonsAction,
  removeActiveLayer: removeActiveLayerAction,
  hideBigImage: hideBigImageAction
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
