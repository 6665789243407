export const reportTypes = {
  SIMPLIFIED: 'summary',
  DETAILED: 'detailed'
};

export const imageSize = {
  ORIGINAL: 'original',
  RESIZED: 'resized'
};

export const SEVERITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
};

export const DEFAULT_FILTER_VALUES = {
  BRIGHTNESS: 100,
  CONTRAST: 100
};

export const DATA_OWNERS = {
  DIAR: 'DIAR',
  IT: 'IT',
  ASD: 'ASD',
  ERO: 'ERO',
  CMG: 'CMG',
  TCM: 'TCM',
  MIC: 'Mobile',
  RIDI: 'CMP',
  FSD: 'FSD'
};

export const IMAGE_SOURCES = {
  CAMERA: 'Camera',
  DRONE: 'Drone',
  FLEET: 'Fleet',
  MOBILE: 'Mobile'
};

export const networkTypes = [
  { name: 'Transmission', value: 'TRANSMISSION' },
  { name: 'Distribution', value: 'DISTRIBUTION' },
  { name: 'Vault', value: 'VAULT' },
  { name: 'Pad Mount', value: 'PAD MOUNT' },
  { name: 'Handhole', value: 'HANDHOLE' },
  { name: 'Manhole', value: 'MANHOLE' },
  { name: 'Subsurface Enclosure', value: 'SUBSURFACE ENCLOSURE' }
];

export const networkTypesNoTransmission = [
  { name: 'Distribution', value: 'DISTRIBUTION' },
  { name: 'Vault', value: 'VAULT' },
  { name: 'Pad Mount', value: 'PAD MOUNT' },
  { name: 'Handhole', value: 'HANDHOLE' },
  { name: 'Manhole', value: 'MANHOLE' },
  { name: 'Subsurface Enclosure', value: 'SUBSURFACE ENCLOSURE' }
];

export const infrastructureTypes = [
  { name: 'Overhead', value: 'OVERHEAD', networkTypes: [
    { name: 'Transmission', value: 'TRANSMISSION' },
    { name: 'Distribution', value: 'DISTRIBUTION' }
  ]},
  { name: 'Surface', value: 'SURFACE', networkTypes: [
    { name: 'Vault', value: 'VAULT' },
    { name: 'Pad Mount', value: 'PAD MOUNT' }
  ]},
  { name: 'Subsurface', value: 'SUBSURFACE', networkTypes: [
    { name: 'Handhole', value: 'HANDHOLE' },
    { name: 'Manhole', value: 'MANHOLE' },
    { name: 'Subsurface Enclosure', value: 'SUBSURFACE ENCLOSURE' }
  ]}
];

export const infrastructureTypesNoTransmission = [
  { name: 'Overhead', value: 'OVERHEAD', networkTypes: [
    { name: 'Distribution', value: 'DISTRIBUTION' }
  ]},
  { name: 'Surface', value: 'SURFACE', networkTypes: [
    { name: 'Vault', value: 'VAULT' },
    { name: 'Pad Mount', value: 'PAD MOUNT' }
  ]},
  { name: 'Subsurface', value: 'SUBSURFACE', networkTypes: [
    { name: 'Handhole', value: 'HANDHOLE' },
    { name: 'Manhole', value: 'MANHOLE' },
    { name: 'Subsurface Enclosure', value: 'SUBSURFACE ENCLOSURE' }
  ]}
];

export const structureTypes = [
  { name: 'Transformer', value: 'TRANSFORMER' },
  { name: 'Switch', value: 'SWITCH' }
];

// request body size constraint in bytes
export const AWS_WAF_SIZE_CONSTRAINT = 8192;

export const defaultImagesGalleryFilters = {
  captureDate: null,
  dataOwner: null,
  imageSource: null,
  startDate: null,
  endDate: null,
  angle: null
};

export const defaultAssetFilters = {
  asset: null,
  assetType: null,
  material: null,
  vendor: null,
  configuration: null,
  status: null
};

export const DISTRICTS = {
  OPERATIONAL: 'OPERATIONAL',
  FIRE: 'FIRETHREAT',
  LIDAR: 'LIDAR'
};
