import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import classnames from 'classnames';

function LoaderWrap({ children, className, isLoading }) {
  return (
    <div
      className={classnames('loader-wrapper', className, {
        'ant-spin-nested-loading': isLoading
      })}
    >
      <div className={isLoading ? 'ant-spin-container ant-spin-blur' : ''}>{children}</div>
      {isLoading ? (
        <div>
          <Spin tip="Loading..." />
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.loaders.local[ownProps.loader]
});

LoaderWrap.propTypes = {
  loader: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

export default connect(mapStateToProps)(LoaderWrap);
