import { combineReducers } from 'redux';
import { MapStateReducer as mapState } from '@innovation-toolkit/mapbox';
import { sidebarReducer as sidebar, mapReducer as map } from '@innovation-toolkit/iip-ui';
import main from './commonReducers/mainReducer';
import infrastructure from '../containers/infrastructure/reducer';
import { loaders, responsive } from '../containers/utils/reducers';
// import sidebar from '../containers/sidebar/reducer';
import datasets from '../containers/datasets/reducer';
import reports from '../containers/reports/reducer';
import wsd from '../containers/wsd/reducer';
import uas from '../containers/uas/reducer';
import view from './commonReducers/viewReducer';

const rootReducer = combineReducers({
  main, // config & auth, rename?
  view,
  map,
  infrastructure,
  loaders,
  sidebar,
  responsive,
  datasets,
  reports,
  uas,
  wsd,
  mapState
});

export default rootReducer;
